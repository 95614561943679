<template>
    <div class="card-margin-bottom">
        <el-form inline>
            <el-form-item label="Raportin tyyppi">
                <ComSelect v-model="valittu">
                    <el-option v-for="(t, index) in tyyppi" :key="index" :value="t" :label="t"></el-option>
                </ComSelect>
            </el-form-item>
            <el-form-item v-if="valittu == 'Huonekortti'">
                <el-checkbox v-model="liitteet">Lataa liitteet</el-checkbox>
            </el-form-item>
            <el-form-item>
                <el-space>
                    <ComSuccessButton
                        v-if="valittu != 'Nimike'"
                        @click="tulosta(1)"
                        v-loading.fullscreen.lock="loadingRaportti"
                        type="success">
                        Pdf
                    </ComSuccessButton>
                    <ComSuccessButton @click="tulosta(2)" v-loading.fullscreen.lock="loadingRaportti" type="success">
                        Excel
                    </ComSuccessButton>
                </el-space>
            </el-form-item>
        </el-form>
        <el-row>
            <el-col :span="24">
                <el-alert 
                    show-icon
                    v-if="liitteet" 
                    type="info" 
                    title="Liitteet latautuvat erillisenä zip-tiedostona. Tarkista selaimen lataukset." 
                />
            </el-col>
        </el-row>
    </div>
    <template v-if="valittu == 'Huonekortti'">
        <ComHuonekorttiRaportti />
    </template>
    <template v-if="valittu == 'Tila'">
        <ComTilaRaportti @huonekorteittain="(val) => huonekorteittain = val" />
    </template>
    <template v-if="valittu == 'Historia'">
        <ComMuutoslogiRaportti @range="(val) => historiaRange = val " />
    </template>
    <template v-if="valittu == 'Nimike'">
        <ComNimikeRaportti @huonekorteittain="(val) => huonekorteittain = val" @nimikkeet="(val) => nimikkeet = val" />
    </template>
</template>

<script setup lang="ts">
import ComSelect from "@/components/common/ComSelect.vue"
import ComMuutoslogiRaportti from "./ComMuutoslogiRaportti.vue"
import ComNimikeRaportti from "./ComNimikeRaportti.vue"
import ComTilaRaportti from "./ComTilaRaportti.vue"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComHuonekorttiRaportti from "./ComHuonekorttiRaportti.vue"
import { raportointi } from "./raportointi"

const { valittu, tyyppi, loadingRaportti, huonekorteittain, historiaRange, nimikkeet, liitteet, tulosta } = raportointi()


</script>
