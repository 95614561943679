import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { ref, watch } from "vue"
import { useLocalStorage } from "@vueuse/core"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComPagination',
  props: /*@__PURE__*/_mergeModels({ total: Number }, {
    "currentPage": { default: 1 },
    "currentPageModifiers": {},
    "pageSize": {},
    "pageSizeModifiers": {},
  }),
  emits: ["update:currentPage", "update:pageSize"],
  setup(__props) {


const pageSizeStorage = useLocalStorage("huonekorttiPageSize", 30)
const currentPage = _useModel<number>(__props, "currentPage")
const pageSize = _useModel<number>(__props, "pageSize")

if (pageSizeStorage.value) {
    pageSize.value = pageSizeStorage.value
}

watch(pageSize, () => {
    pageSizeStorage.value = pageSize.value
    currentPage.value = 1
})

const pageSizes = ref([10, 30, 60, 100])

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (__props.total)
    ? (_openBlock(), _createBlock(_component_el_pagination, {
        key: 0,
        layout: "total, prev, pager, next, sizes",
        total: __props.total,
        "page-size": pageSize.value,
        "onUpdate:pageSize": _cache[0] || (_cache[0] = ($event: any) => ((pageSize).value = $event)),
        "current-page": currentPage.value,
        "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((currentPage).value = $event)),
        "page-sizes": pageSizes.value,
        size: "small"
      }, null, 8, ["total", "page-size", "current-page", "page-sizes"]))
    : _createCommentVNode("", true)
}
}

})