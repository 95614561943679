<template>
    <tr style="height: 40px">
        <td style="width: 15%">
            {{ _nimike?.nimi }}
            <template v-if="!_nimike?.kaytossa === true">
                <el-button text @click.prevent="" size="small" circle>
                    <ComTooltip content="Nimike on poistettu nimikkeistöstä. Korvaa nimike toisella.">
                        <el-icon :size="20">
                            <Warning />
                        </el-icon>
                    </ComTooltip>
                </el-button>
            </template>
        </td>
        <td style="width: 5%">
            <template v-if="edited">
                <el-input-number :min="0" class="number-input-mini" size="small" v-model="request.maara"></el-input-number>
            </template>
            <span v-else v-html="nimike.maara" />
        </td>
        <td style="width: 35%">
            <template v-if="edited">
                <el-input v-model="request.tasmennys" type="textarea" autosize style="width: 100%"></el-input>
            </template>
            <span v-else v-html="nimike.tasmennys" />
        </td>
        <td style="width: 35%">
            <template v-if="edited">
                <el-select
                    multiple
                    v-model="request.ominaisuudet"
                    placeholder="Valitse ominaisuudet"
                    size="small"
                    style="width: 80%"
                    value-key="id">
                    <el-option
                        v-for="(ominaisuus, index) in ominaisuudet"
                        :key="index"
                        :label="ominaisuus.nimi"
                        :value="ominaisuus"></el-option>
                </el-select>
            </template>
            <template v-else-if="nimike.ominaisuudet">
                <el-tag
                    type="info"
                    size="small"
                    style="margin-right: 2px"
                    v-for="(ominaisuus, index) in nimike.ominaisuudet"
                    :key="index">
                    <span v-html="ominaisuus.nimi" />
                </el-tag>
            </template>
        </td>
        <td style="padding-right: 20px">
            <div style="width: 135px">
                <el-space>
                    <ComSaveButton v-if="edited" @click="update"></ComSaveButton>
                    <ComCancelIconButton v-if="edited" @click="cancel"></ComCancelIconButton>
                    <ComEditButton v-if="kirjoitus && !edited"  @click="edit"></ComEditButton>
                    <ComDeleteButton v-if="kirjoitus && !edited" @click="del"></ComDeleteButton>
                    <el-badge v-if="!edited" :value="kommenttiCount" :hidden="!kommenttiCount">
                        <ComCommentButton @click="showKommentit(nimike.id, 4)"></ComCommentButton>
                    </el-badge>
                    <el-badge v-if="!edited" :value="liiteCount" :hidden="!liiteCount">
                        <ComAttatchmentButton @click="showLiitteet(nimike.id, 4)"></ComAttatchmentButton>
                    </el-badge>
                </el-space>
            </div>
        </td>
    </tr>
</template>

<script setup lang="ts">
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response"
import { useNimikeUpdate, useNimikeDelete } from "@/methods/huonekorttiComposablses"
import { useNimike } from "@/methods/nimikkeetComposables"
import { computed, PropType, ref, watch } from "vue"
import { Warning } from "@element-plus/icons-vue"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComEditButton from "../common/buttons/ComEditButton.vue"
import ComTooltip from "../common/ComTooltip.vue"
import ComCancelIconButton from "../common/buttons/ComCancelIconButton.vue"
import ComSaveButton from "../common/buttons/ComSaveButton.vue"
import { HuonekorttiNimikeRequestPut } from "@/api/models/huonekortti-nimike-request-put"
import { ElMessage, ElMessageBox } from "element-plus"
import { huonekorttiPage } from "@/views/huonekortti"
import { useSuunnittelutiedot } from "./suunnittelutiedot"
import { OminaisuusResponse } from "@/api/models/ominaisuus-response"
import ComAttatchmentButton from "../common/buttons/ComAttatchmentButton.vue"
import ComCommentButton from "../common/buttons/ComCommentButton.vue"

const props = defineProps({
    nimike: {
        type: Object as PropType<HuonekorttiNimikeResponse>,
        required: true,
    },
}) 

const nimikeId = ref<number>() 
const id = ref<number>()
const { kirjoitus, showLiitteet, showKommentit } = huonekorttiPage()
const { cancelEdit, setEdit, liitteetCount, kommentitCount } = useSuunnittelutiedot()
const { error: updateError, loading: updateLoading, response: updateResonse, updateNimike } = useNimikeUpdate()
const { error: deleteError, loading: deleteLoading, response: deleteResponse, deleteNimike } = useNimikeDelete()
const { nimike : _nimike } = useNimike(nimikeId)
const ominaisuudet = computed(() => _nimike.value?.ominaisuudet ?? [])
const edited = ref<boolean>(false)
const request = ref<HuonekorttiNimikeRequestPut>({})
const liiteCount = ref<number>()
const kommenttiCount = ref<number>()

const edit = () => {
    edited.value = !edited.value

    request.value = {
        maara: props.nimike.maara,
        tasmennys: props.nimike.tasmennys,
        ominaisuudet: props.nimike.ominaisuudet?.map((ominaisuus: OminaisuusResponse) => {
            return { id: ominaisuus.id }
        }),
        
    } 

    setEdit(props.nimike.id)
}
const cancel = () => {
    edited.value = false

    cancelEdit(props.nimike.id)
}
const update = async () => {
    if(request.value){ 
        updateNimike(props.nimike.id, request.value) 
    }
}
const del = async () => {
    ElMessageBox.confirm("Haluatko varmasti poistaa nimikkeen: " + props.nimike.nimike?.nimi).then(async () => {
        await deleteNimike(props.nimike.id)
    })
}

watch(updateLoading, (value) => {
    if (!value && updateResonse.value) {
        edited.value = false
        cancelEdit(props.nimike.id)

        ElMessage.success("Nimike päivitetty")
    }
})
watch(updateError, (value) => {
    if (value) {
        ElMessage.error("Nimikkeen päivitys epäonnistui")
    }
})
watch(deleteLoading, (value) => {
    if (!value && deleteResponse.value) {
        edited.value = false

        ElMessage.success("Nimikkeen poistettu")
    }
})
watch(deleteError, (value) => {
    if (value) {
        ElMessage.error("Nimikkeen poisto epäonnistui")
    }
})
watch(liitteetCount, (value) => {
    if(value){
        liiteCount.value = value.find(l => l.id == id.value)?.count
    }
})
watch(kommentitCount, (value) => {
    if(value){
        kommenttiCount.value = value.find(l => l.id == id.value)?.count
    }
})
watch(() => props.nimike, (value) => {
    nimikeId.value = value.nimike?.id
    id.value = value.id
}, { immediate: true, deep: true })

</script>
