import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card-margin-bottom" }

import ComHankkeet from "@/components/etusivu/ComHankkeet.vue"
import ComReleaseNotes from "@/components/etusivu/ComReleaseNotes.vue"


const __default__ = {
    name: "EtusivuView",
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(ComHankkeet)
    ]),
    _createElementVNode("div", null, [
      _createVNode(ComReleaseNotes)
    ])
  ], 64))
}
}

})