<template>
    <h3 v-if="showHeading(1)">Suunnittelutiedot</h3>
    <el-form :model="suunnittelutiedot" @submit.prevent ref="form" :inline="false" size="small">
        <el-row :gutter="20">
            <template v-if="isSelected('nimike')">
                <el-col :span="14" >
                    <el-form-item>
                        <el-cascader-panel
                        :options="nodes"
                        :props="{
                            disabled: false,
                            multiple: true,
                        }"
                        size="small"
                        v-model="selectedNodes"
                        style="width: 100%"
                        @change="selectionChange"
                        >
                            <template #default="{ node, data }">
                                <span v-if="!node.isLeaf">{{ data.label }}</span>
                                <div v-if="node.isLeaf" style="width: 100%">
                                    <div style="float: left;" >{{ data.label }}</div>
                                    <div v-if="node.checked" style="float: right; padding-left: 10px;">
                                        <ComPlusButton v-if="!isSelectedNimike(data.value)" @click="cascaderFocus(data)" />
                                        <ComMinusButton v-if="isSelectedNimike(data.value)" @click="cascaderFocus(data)" />
                                    </div>
                                </div>
                            </template>
                        </el-cascader-panel>
                </el-form-item>
                </el-col>
                <el-col :span="10" v-if="showTiedot.length > 0">
                    <h3>Tarkenna nimikkeiden haku termejä</h3>
                    <template v-for="(selectedNimike, i) in searchForm.nimike" :key="selectedNimike.id">
                        <template v-if="show(selectedNimike.id) && searchForm.nimike">
                            <ComHakuSuunnittelutiedotOminaisuudet :show="show(selectedNimike.id)" v-model="searchForm.nimike[i]" />
                        </template>
                    </template>
                </el-col>
            </template>
        </el-row>
    </el-form>
</template>

<script setup lang="ts">
import { computed, ref, toRef } from 'vue';
import { useHakuComponent } from './haku';
import { setNodes, useNimikeKategoria } from '@/methods/nimikkeetComposables';
import ComHakuSuunnittelutiedotOminaisuudet from './ComHakuSuunnittelutiedotOminaisuudet.vue';
import ComPlusButton from '@/components/common/buttons/ComPlusButton.vue';
import ComMinusButton from '@/components/common/buttons/ComMinusButton.vue';

const { searchForm, selectedNodes, isSelected, showHeading } = useHakuComponent()
const suunnittelutiedot = computed(() => searchForm.value.nimike)
const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
const showTiedot = ref<number[]>([])
const nodes = computed(() => setNodes(nimikeKategoriat.value ?? [])) 

const isSelectedNimike = (id : number) => {
    return showTiedot.value?.find(s => s === id) ? true : false
}

const removeFromSelected = (id : number) => {
    showTiedot.value = showTiedot.value.filter(s => s !== id)
}

const cascaderFocus = (data : {disabled: boolean, value: number, label: string}) => {
    if(!isSelectedNimike(data.value)) {
        showTiedot.value.push(data.value)
    }
    else {
        removeFromSelected(data.value)
        searchForm.value.nimike?.find((n, i) => {
            if(n.id === data.value && searchForm.value.nimike) {
                searchForm.value.nimike[i] = { id: data.value }
            }
        })
    }
}

const show = (id : number | undefined) => {
    return showTiedot.value?.find(s => s === id) ? true : false
}

const selectionChange = (val : number[][]) => {
    if(val.length === 0) {
        searchForm.value.nimike = []
        showTiedot.value = []
    } else {
        searchForm.value.nimike?.forEach((n, i) => {
            if(!val.find(v => v[2] === n.id) && searchForm.value.nimike) {
                delete searchForm.value.nimike[i]

                removeFromSelected(i)
            }
        })

        searchForm.value.nimike = searchForm.value.nimike?.filter(Boolean)

        val.forEach(v => {
            if(!searchForm.value.nimike?.find(n => n.id === v[2])) {
                searchForm.value.nimike?.push({ id: v[2] })
            }
        })
    }
}

</script>