import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

import { useNimike } from '@/methods/nimikkeetComposables';
import { useNimikeMuokkausComponent } from './nimikeMuokkaus';
import { ref } from 'vue';
import { OminaisuusResponse } from '@/api/models/ominaisuus-response';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNimikeMuokkausPoistaOminaisuus',
  setup(__props) {

const { nimikeId, showPoistaOminaisuudet, removeOminaisuudet } = useNimikeMuokkausComponent()
const { nimike } = useNimike(nimikeId)
const poistaOminaisuudet = ref<OminaisuusResponse[]>([])

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_unref(nimike))
    ? (_openBlock(), _createBlock(_component_el_select, {
        key: 0,
        modelValue: poistaOminaisuudet.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((poistaOminaisuudet).value = $event)),
        "value-key": "id",
        multiple: "",
        size: "small",
        "collapse-tags": "",
        placeholder: "Poista ominaisuudet",
        style: {"width":"150px"}
      }, {
        footer: _withCtx(() => [
          _createVNode(ComCancelButton, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showPoistaOminaisuudet.value = false))
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Peruuta")
            ])),
            _: 1
          }),
          _createVNode(ComSuccessButton, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(removeOminaisuudet)(poistaOminaisuudet.value)))
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Poista")
            ])),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(nimike).ominaisuudet, (ominaisuus) => {
            return (_openBlock(), _createBlock(_component_el_option, {
              key: ominaisuus.id,
              label: ominaisuus.nimi,
              value: ominaisuus
            }, null, 8, ["label", "value"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"]))
    : _createCommentVNode("", true)
}
}

})