<template>
    <el-card class="el-card-blank" shadow="never">
        <el-form-item label="" >
            <el-radio-group v-model="searchType" >
                <el-radio-button label="Huonekortti haku" :value="1" />
                <el-radio-button label="Tila haku" :value="2" />
                <el-radio-button label="Nimike haku" :value="3" />
            </el-radio-group>
        </el-form-item>
        <el-form-item label="Valitse hakukentät">
            <el-select multiple v-model="selectedTerms">
                <el-option-group v-for="group in searchTerms" :key="group.label" :label="group.label">
                    <el-option
                        v-for="item in group.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-option-group>
            </el-select>
        </el-form-item>
        <ComHakuToimintatiedot/>
        <ComHakuSuunnittelutiedot/>
        <ComHakuTilat/>
        <el-form-item>
            <ComSuccessButton @click="hae(true)" >Hae</ComSuccessButton>
        </el-form-item>
    </el-card>
    <el-divider border-style="none"/>
    <el-row>
        <el-col :span="6">
            <el-form-item>
                <ComSuccessButton v-if="showMuokkaa" @click="showNimikeEditModal" >Muokkaa valittuja nimikkeitä</ComSuccessButton>
            </el-form-item>
        </el-col>
        <el-col :span="18">
            <ComPagination
                :total="searchTotal"
                v-model:page-size="searchForm.paging.per_page"
                v-model:current-page="searchForm.paging.page"
                style="float: right"
                @change="hae(false)" 
            />
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <ComLoading v-if="searchLoading" />
            <ComHuonekorttiResultTable v-else-if="searchType == 1"/>
            <ComTilaResultTable v-else-if="searchType == 2"/>
            <ComNimikeResultTable v-else-if="searchType == 3"/>
        </el-col>
    </el-row>
    <el-drawer
        title="Muokkaa"
        v-model="nimikeEditModalVisible"
        size="70%"
        :close-on-click-modal="false"
        :destroy-on-close="true"
    >
        <ComNimikeMuokkaus :nimikeet="selectedNimikkeet"/>
        <template #footer>
            <ComCancelButton @click="hideNimikeEditModal">Peruuta</ComCancelButton>
            <ComSuccessButton @click="tallennaNimikkeet">Tallenna</ComSuccessButton>
        </template>
    </el-drawer>
</template>

<script setup lang="ts">
import ComHakuToimintatiedot from './ComHakuToimintatiedot.vue';
import { useHakuComponent } from './haku';
import ComHakuSuunnittelutiedot from './ComHakuSuunnittelutiedot.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import ComHuonekorttiResultTable from './ComHuonekorttiResultTable.vue';
import ComTilaResultTable from './ComTilaResultTable.vue';
import ComLoading from '@/components/common/ComLoading.vue';
import ComPagination from '@/components/common/ComPagination.vue';
import { watch } from 'vue';
import ComHakuTilat from './ComHakuTilat.vue';
import { Operator } from '@/api/models/operator';
import ComNimikeResultTable from './ComNimikeResultTable.vue';
import { useNimikeSelection } from './nimikeSelection';
import ComNimikeMuokkaus from './muokkaus/ComNimikeMuokkaus.vue';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import { useNimikeMuokkausComponent } from './muokkaus/nimikeMuokkaus';
import { hankePage } from '@/views/hanke';

const { searchType, searchLoading, searchTerms, selectedTerms, searchTotal, searchForm, hae } = useHakuComponent()
const { showNimikeEditModal, hideNimikeEditModal, selectedNimikkeet, showMuokkaa, nimikeEditModalVisible } = useNimikeSelection()
const { tallennaNimikkeet } = useNimikeMuokkausComponent()
const { selectedTab } = hankePage()

const enterHandler = (e: KeyboardEvent) => {
    if(e.key === 'Enter') {
        hae(true)
    }
}

watch(selectedTerms, (value) => {
    if(!value.includes('tilanimike')) {
        searchForm.value.huonekortti.tilanimike = []
    }
    if(!value.includes('tilanimike-tasmennys')) {
        searchForm.value.huonekortti['tilanimike-tasmennys'] = undefined
    }
    if(!value.includes('toiminnan-kuvaus')) {
        searchForm.value.huonekortti['toiminnan-kuvaus'] = undefined
    }
    if(!value.includes('toiminnan-vaatimukset')) {
        searchForm.value.huonekortti['toiminnan-vaatimukset'] = undefined
    }
    if(!value.includes('henkilomaara-norm')) {
        searchForm.value.huonekortti['henkilomaara-norm'] = undefined
    }
    if(!value.includes('henkilomaara-max')) {
        searchForm.value.huonekortti['henkilomaara-max'] = undefined
    }
    if(!value.includes('sahkoluokka')) {
        searchForm.value.huonekortti.sahkoluokka = undefined
    }
    if(!value.includes('toiminta-aika')) {
        searchForm.value.huonekortti['toiminta-aika'] = undefined
    }
    if(!value.includes('status')) {
        searchForm.value.huonekortti.status = undefined
    }
    if(!value.includes('nimike')) {
        searchForm.value.nimike = []
    }
    if(!value.includes('pinta-ala')) {
        searchForm.value.tila['pinta-ala'] = { operator: Operator.Eq, 'pinta-ala': undefined }
    }
    if(!value.includes('tilatunnus')) {
        searchForm.value.tila.tilatunnus = undefined
    }
})
watch(selectedTab, (value) => {
    if(value == 6) {
        document.addEventListener('keyup', enterHandler)
    } else {
        document.removeEventListener('keyup', enterHandler)
    }
}, { immediate: true })

</script>