import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card-margin-bottom" }

import ComSelect from "@/components/common/ComSelect.vue"
import ComMuutoslogiRaportti from "./ComMuutoslogiRaportti.vue"
import ComNimikeRaportti from "./ComNimikeRaportti.vue"
import ComTilaRaportti from "./ComTilaRaportti.vue"
import ComSuccessButton from "@/components/common/buttons/ComSuccessButton.vue"
import ComHuonekorttiRaportti from "./ComHuonekorttiRaportti.vue"
import { raportointi } from "./raportointi"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComRaportointi',
  setup(__props) {

const { valittu, tyyppi, loadingRaportti, huonekorteittain, historiaRange, nimikkeet, liitteet, tulosta } = raportointi()



return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_form, { inline: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, { label: "Raportin tyyppi" }, {
            default: _withCtx(() => [
              _createVNode(ComSelect, {
                modelValue: _unref(valittu),
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(valittu) ? (valittu).value = $event : null))
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tyyppi), (t, index) => {
                    return (_openBlock(), _createBlock(_component_el_option, {
                      key: index,
                      value: t,
                      label: t
                    }, null, 8, ["value", "label"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          (_unref(valittu) == 'Huonekortti')
            ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_checkbox, {
                    modelValue: _unref(liitteet),
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(liitteet) ? (liitteet).value = $event : null))
                  }, {
                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                      _createTextVNode("Lataa liitteet")
                    ])),
                    _: 1
                  }, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_space, null, {
                default: _withCtx(() => [
                  (_unref(valittu) != 'Nimike')
                    ? _withDirectives((_openBlock(), _createBlock(ComSuccessButton, {
                        key: 0,
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(tulosta)(1))),
                        type: "success"
                      }, {
                        default: _withCtx(() => _cache[9] || (_cache[9] = [
                          _createTextVNode(" Pdf ")
                        ])),
                        _: 1
                      })), [
                        [
                          _directive_loading,
                          _unref(loadingRaportti),
                          void 0,
                          {
                            fullscreen: true,
                            lock: true
                          }
                        ]
                      ])
                    : _createCommentVNode("", true),
                  _withDirectives((_openBlock(), _createBlock(ComSuccessButton, {
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(tulosta)(2))),
                    type: "success"
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Excel ")
                    ])),
                    _: 1
                  })), [
                    [
                      _directive_loading,
                      _unref(loadingRaportti),
                      void 0,
                      {
                        fullscreen: true,
                        lock: true
                      }
                    ]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 24 }, {
            default: _withCtx(() => [
              (_unref(liitteet))
                ? (_openBlock(), _createBlock(_component_el_alert, {
                    key: 0,
                    "show-icon": "",
                    type: "info",
                    title: "Liitteet latautuvat erillisenä zip-tiedostona. Tarkista selaimen lataukset."
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    (_unref(valittu) == 'Huonekortti')
      ? (_openBlock(), _createBlock(ComHuonekorttiRaportti, { key: 0 }))
      : _createCommentVNode("", true),
    (_unref(valittu) == 'Tila')
      ? (_openBlock(), _createBlock(ComTilaRaportti, {
          key: 1,
          onHuonekorteittain: _cache[4] || (_cache[4] = (val) => huonekorteittain.value = val)
        }))
      : _createCommentVNode("", true),
    (_unref(valittu) == 'Historia')
      ? (_openBlock(), _createBlock(ComMuutoslogiRaportti, {
          key: 2,
          onRange: _cache[5] || (_cache[5] = (val) => historiaRange.value = val )
        }))
      : _createCommentVNode("", true),
    (_unref(valittu) == 'Nimike')
      ? (_openBlock(), _createBlock(ComNimikeRaportti, {
          key: 3,
          onHuonekorteittain: _cache[6] || (_cache[6] = (val) => huonekorteittain.value = val),
          onNimikkeet: _cache[7] || (_cache[7] = (val) => nimikkeet.value = val)
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})