<template>
    <ComLiiteList />
</template>

<script setup lang="ts">
import { watchEffect } from "vue"
import ComLiiteList from "./ComLiiteList.vue"
import kommenttiTyyppi from "@/types/Kommentti";
import { useLiiteComponent } from "./liite";

const props = defineProps<{
    viite: number | undefined
    tyyppi: kommenttiTyyppi | undefined
}>()
const { viite: _viite, tyyppi: _tyyppi } = useLiiteComponent()

watchEffect(() => {
    if (props.viite == undefined || props.tyyppi == undefined) {
        return
    }
    
    _viite.value = props.viite
    _tyyppi.value = props.tyyppi
})

</script>
