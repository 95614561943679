import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"float":"right"} }

import ComPageHeader from "../components/layout/ComPageHeader.vue"
import ComToimintatiedot from "@/components/huonekortti/ComToimintatiedot.vue"
import ComSuunnittelutiedot from "@/components/huonekortti/ComSuunnittelutiedot.vue"
import ComHanketiedot from "@/components/huonekortti/hanketiedot/ComHanketiedot.vue"
import ComHistoria from "@/components/huonekortti/historia/ComHistoria.vue"
import ComLoading from "@/components/common/ComLoading.vue"
import ComHuonekorttiInfoDropDownButton from "@/components/huonekortti/ComHuonekorttiInfoDropDownButton.vue"
import ComKommentointi from "@/components/kommentointi/ComKommentointi.vue"
import ComLiitteet from "@/components/liitteet/ComLiitteet.vue"
import ComCard from "@/components/common/ComCard.vue"
import { huonekorttiPage } from "./huonekortti"
import ComAttatchmentButton from "@/components/common/buttons/ComAttatchmentButton.vue"
import ComCommentButton from "@/components/common/buttons/ComCommentButton.vue"
import { ref, watch } from "vue"


const __default__ = {
    name: "HuonekorttiView",
}

export default /*@__PURE__*/_defineComponent({
  ...__default__,
  setup(__props) {

const {
    huonekortti,
    headerTitle,
    headerSubtitle,
    drawerVisible,
    hanke,
    loading,
    paakayttaja,
    drawerEnum,
    drawerTitle,
    kommenttiTaiLiiteTyyppi,
    kommenttiTaiLiiteViite,
    countKommentti,
    countLiite,
    showKommentit,
    showLiitteet
} = huonekorttiPage()
const showDrawer = ref(false)

watch(drawerVisible, () => {
    if(!drawerVisible) {
        showDrawer.value = false
    } else {
        showDrawer.value = true
    }
})


return (_ctx: any,_cache: any) => {
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_unref(huonekortti))
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _createVNode(ComPageHeader, {
          contentTitle: _unref(headerTitle),
          contentSubtitle: _unref(headerSubtitle)
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_el_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_badge, {
                    value: _unref(countLiite),
                    hidden: !_unref(countLiite)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(ComAttatchmentButton, {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(showLiitteet)(_unref(huonekortti).id, 1)))
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "hidden"]),
                  _createVNode(_component_el_badge, {
                    value: _unref(countKommentti),
                    hidden: !_unref(countKommentti)
                  }, {
                    default: _withCtx(() => [
                      _createVNode(ComCommentButton, {
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(showKommentit)(_unref(huonekortti).id, 1)))
                      })
                    ]),
                    _: 1
                  }, 8, ["value", "hidden"]),
                  _createVNode(ComHuonekorttiInfoDropDownButton)
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        }, 8, ["contentTitle", "contentSubtitle"]),
        _createVNode(ComToimintatiedot, {
          data: _unref(huonekortti),
          class: "card-margin-bottom"
        }, null, 8, ["data"]),
        _createVNode(ComSuunnittelutiedot),
        _createVNode(_component_el_drawer, {
          modelValue: showDrawer.value,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((showDrawer).value = $event)),
          size: "50%",
          title: _unref(drawerTitle),
          "destroy-on-close": "",
          onClose: _cache[3] || (_cache[3] = ($event: any) => (drawerVisible.value = undefined))
        }, {
          default: _withCtx(() => [
            (_unref(drawerVisible) == _unref(drawerEnum).liitteet)
              ? (_openBlock(), _createBlock(ComLiitteet, {
                  key: 0,
                  viite: _unref(kommenttiTaiLiiteViite),
                  tyyppi: _unref(kommenttiTaiLiiteTyyppi)
                }, null, 8, ["viite", "tyyppi"]))
              : _createCommentVNode("", true),
            (_unref(drawerVisible) == _unref(drawerEnum).kommentointi)
              ? (_openBlock(), _createBlock(ComKommentointi, {
                  key: 1,
                  paakayttaja: _unref(paakayttaja) ?? false,
                  viite: _unref(kommenttiTaiLiiteViite),
                  tyyppi: _unref(kommenttiTaiLiiteTyyppi)
                }, null, 8, ["paakayttaja", "viite", "tyyppi"]))
              : _createCommentVNode("", true),
            (_unref(drawerVisible) == _unref(drawerEnum).hanketiedot)
              ? (_openBlock(), _createBlock(ComHanketiedot, {
                  key: 2,
                  huonekorttiId: _unref(huonekortti).id,
                  hankeId: _unref(hanke)?.id,
                  editable: false
                }, null, 8, ["huonekorttiId", "hankeId"]))
              : _createCommentVNode("", true),
            (_unref(drawerVisible) == _unref(drawerEnum).historia)
              ? (_openBlock(), _createBlock(ComHistoria, {
                  key: 3,
                  huonekortti: _unref(huonekortti)
                }, null, 8, ["huonekortti"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["modelValue", "title"])
      ], 64))
    : (_unref(loading))
      ? (_openBlock(), _createBlock(ComCard, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(ComLoading)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}
}

})