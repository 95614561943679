import { Ref, computed, ref } from "vue"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { TilaResponse } from "@/api/models/tila-response"
import { HuonekorttiNimikeRequestPut } from "@/api/models/huonekortti-nimike-request-put"
import { HuonekorttiNimikeRequest } from "@/api/models/huonekortti-nimike-request"
import { HuonekorttiRequestPut } from "@/api/models/huonekortti-request-put"
import { HuonekorttiRequest } from "@/api/models/huonekortti-request"
import http from "@/http-common"
import { TilaRequest } from "@/api/models/tila-request"
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query"
import { HuonekorttiId } from "@/api/models/huonekortti-id"
import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response"

export function useHuonekortti(id: Ref<number | undefined>) {
    const { formatTilat } = useHuonekorttiFormatTilat()
    const key = computed(() => ["huonekortit", id.value])
    const loading = computed(() => !huonekortti.value && !error.value)
    const vastuuyksikko = computed(() => huonekortti.value?.vastuuyksikko ?? undefined)
    const tilatString = computed(() => (huonekortti?.value?.tilat ? formatTilat(huonekortti.value.tilat) : undefined))

    const fetcher = async (): Promise<HuonekorttiResponse> => {
        return (await http.get(`huonekortit/${id.value}`)).data
    }

    const {
        data: huonekortti,
        error,
    } = useQuery({
        queryKey: key,
        queryFn: () => fetcher(),
        enabled: () => id.value !== undefined,
    })

    return {
        huonekortti,
        error,
        loading,
        vastuuyksikko,
        tilatString,
    }
}

export function useHuonekorttiUpdate() {
    const queryClient = useQueryClient()
    const {
        data: response,
        error,
        isPending: loading,
        mutate,
    } = useMutation({
        mutationFn: (request: { id: number; request: HuonekorttiRequestPut }) =>
            http.put(`huonekortit/${request.id}`, request.request),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
            queryClient.invalidateQueries({queryKey: ["tilat/hankeId/"]})
        },
    })

    const updateHuonekortti = async (id: number, huonekortti: HuonekorttiRequestPut) => {
        mutate({ id: id, request: huonekortti })
    }

    return {
        response,
        error,
        loading,
        updateHuonekortti,
    }
}

export function useHuonekorttiDelete() {
    const queryClient = useQueryClient()
    const {
        data: response,
        error,
        isPending: loading,
        mutate,
    } = useMutation({
        mutationFn: (id: number) => http.delete(`huonekortit/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
            queryClient.invalidateQueries({queryKey: ["tilat/hankeId/"]})
        },
    })

    const deleteHuonekortti = (id: number) => {
        mutate(id)
    }

    return {
        response,
        error,
        loading,
        deleteHuonekortti,
    }
}

export function useHuonekorttiUndelete() {
    const queryClient = useQueryClient()
    const {
        data: response,
        error,
        isPending: loading,
        mutate,
    } = useMutation({
        mutationFn: (id: number) => http.post(`huonekortit/${id}/palauta`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
            queryClient.invalidateQueries({queryKey: ["tilat/hankeId/"]})
        },
    })

    const undeleteHuonekortti = (id: number) => {
        mutate(id)
    }

    return {
        response,
        error,
        loading,
        undeleteHuonekortti,
    }
}


export function useHuonekorttiFormatTilat() {
    const formatTilat = (tilat: TilaResponse[], pintaAla : boolean = false): string => {
        if (tilat != undefined) {
            return tilat
                .map((t: TilaResponse) => {
                    return t.tilatunnus + (pintaAla && t.pintaAla ? ` (${t.pintaAla} m²)` : "")
                })
                .join(", ")
        } else {
            return ""
        }
    }
    return {
        formatTilat,
    }
}

export function useHuonekorttiFormatLinkWithTilat() {
    const formatLinkWithTilat = (val: HuonekorttiResponse) => {
        const { formatTilat } = useHuonekorttiFormatTilat()

        let tilat = ""

        if (val.tilat) {
            tilat = formatTilat(val.tilat)
        }
        if (val && val.tilanimike) {
            return `${val.tilanimike.nimi} (${tilat})`
        } else {
            return ""
        }
    }
    return {
        formatLinkWithTilat,
    }
}

export function useNimikeUpdate() {
    const queryClient = useQueryClient()
    const {
        data: response,
        error,
        isPending: loading,
        mutate,
    } = useMutation({
        mutationFn: async (request: { id: number; nimike: HuonekorttiNimikeRequestPut }) : Promise<HuonekorttiNimikeResponse> =>
            (await http.put(`huonekortit/nimikkeet/${request.id}`, request.nimike)).data,
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["huonekortit"] }),
    })

    const updateNimike = (id: number, nimike: HuonekorttiNimikeRequestPut) => {
        mutate({ id: id, nimike: nimike })
    }

    return {
        response,
        error,
        loading,
        updateNimike,
    }
}

export function useNimikeetUpdate() {
    const responses = ref<HuonekorttiNimikeResponse[]>([])
    const errors = ref<number[]>([])
    const loading = ref(false)
    const queryClient = useQueryClient()

    const {
        data: response,
        error,
        mutate,
    } = useMutation({
        mutationFn: async (request: { id: number; nimike: HuonekorttiNimikeRequestPut }) : Promise<HuonekorttiNimikeResponse> =>
            (await http.put(`huonekortit/nimikkeet/${request.id}`, request.nimike)).data,
        onSuccess: (data) => {
            responses.value.push(data)
        },
        onError: (error, variables) => {
            errors.value.push(variables.id)
        }
    })

    const updateNimikeet = (nimikkeet: { id: number, nimike: HuonekorttiNimikeRequestPut}[]) => {
        loading.value = true

        nimikkeet.forEach(nimike => {
            mutate({id: nimike.id, nimike: nimike.nimike}, {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
                },
                onSettled: () => {
                    loading.value = false
                }
            })
        })
    }

    return {
        responses,
        response,
        error,
        errors,
        loading,
        updateNimikeet,
    }
}

export function useNimikeDelete() {
    const queryClient = useQueryClient()
    const {
        data: response,
        error,
        isPending: loading,
        mutate,
    } = useMutation({
        mutationFn: (id: number) => http.delete(`huonekortit/nimikkeet/${id}`),
        onSuccess: () => queryClient.invalidateQueries({ queryKey: ["huonekortit"] }),
    })

    const deleteNimike = (id: number) => {
        mutate(id)
    }

    return {
        response,
        error,
        loading,
        deleteNimike,
    }
}

export function useNimikeAdd() {
    const queryClient = useQueryClient()
    const {
        data: response,
        error,
        isPending: loading,
        mutate,
    } = useMutation({
        mutationFn: (request: { id: number; nimike: HuonekorttiNimikeRequest }) =>
            http.post(`huonekortit/${request.id}/nimikkeet`, request.nimike),
        onSuccess: (data, variables) => queryClient.invalidateQueries({ queryKey: ["huonekortit", variables.id] }),
    })

    const addNimike = (id: number, nimike: HuonekorttiNimikeRequest) => {
        mutate({ id: id, nimike: nimike })
    }

    return {
        response,
        error,
        loading,
        addNimike,
    }
}

export function useNimikeetAdd() {
    const responses = ref<HuonekorttiNimikeResponse[]>([])
    const errors = ref<number[]>([])
    const loading = ref(false)
    const queryClient = useQueryClient()

    const {
        data: response,
        error,
        mutate,
    } = useMutation({
        mutationFn: async (request: { id: number; nimike: HuonekorttiNimikeRequest}) : Promise<HuonekorttiNimikeResponse> =>
            (await http.post(`huonekortit/${request.id}/nimikkeet`, request.nimike)).data,
        onSuccess: (data) => {
            responses.value.push(data)
        },
        onError: (error, variables) => {
            errors.value.push(variables.id)
        }
    })

    const addNimikkeet = (id: number, nimikkeet: HuonekorttiNimikeRequest[]) => {
        loading.value = true

        nimikkeet.forEach(nimike => {
            mutate({id: id, nimike: nimike}, {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
                },
                onSettled: () => {
                    loading.value = false
                }
            })
        })
    }

    return {
        responses,
        response,
        error,
        errors,
        loading,
        addNimikkeet,
    }
}


export function useAddHuonekortti() {
    const queryClient = useQueryClient()
    const {
        mutate,
        error,
        isPending: loading,
        data: response,
    } = useMutation({
        mutationFn: (huonekortti: HuonekorttiRequest) => http.post(`huonekortit`, huonekortti),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
            queryClient.invalidateQueries({queryKey: ["tilat/hankeId/"]})
        },
    })

    const addHuonekortti = (huonekortti: HuonekorttiRequest) => {
        mutate(huonekortti)
    }

    return {
        response,
        error,
        loading,
        addHuonekortti,
    }
}

export function useAddHuonekorttiFromMallihuonekortti() {
    const queryClient = useQueryClient()
    const {
        mutate,
        error,
        isPending: loading,
        data: response,
    } = useMutation({
        mutationFn: (request: { vastuuyksikkoId: number; mallihuonekorttiId: number; tilat?: TilaRequest[] | null }) =>
            http.post("huonekortit/mallihuonekortti", request.tilat, {
                params: {
                    vastuuyksikkoId: request.vastuuyksikkoId,
                    mallihuonekorttiId: request.mallihuonekorttiId,
                },
            }),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
            queryClient.invalidateQueries({queryKey: ["tilat/hankeId/"]})
        },
    })

    const addHuonekorttiFromMallihuonekortti = (
        vastuuyksikkoId: number,
        mallihuonekorttiId: number,
        tilat?: TilaRequest[] | null
    ) => {
        mutate({ vastuuyksikkoId: vastuuyksikkoId, mallihuonekorttiId: mallihuonekorttiId, tilat: tilat })
    }

    return {
        response,
        error,
        loading,
        addHuonekorttiFromMallihuonekortti,
    }
}

export function useJulkaiseHuonekortti() {
    const queryClient = useQueryClient()
    const {
        mutate,
        error,
        isPending: loading,
        data: response,
    } = useMutation({
        mutationFn: (id: number) => http.post(`huonekortit/${id}/julkaise`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
        },
    })

    const julkaiseHuonekortti = (id: number) => {
        mutate(id)
    }

    return {
        response,
        error,
        loading,
        julkaiseHuonekortti,
    }
}

export function useHyvaksytaHuonekortti() {
    const queryClient = useQueryClient()
    const {
        mutate,
        error,
        isPending: loading,
        data: response,
    } = useMutation({
        mutationFn: (id: number) => http.post(`huonekortit/${id}/hyvaksyta`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
        },
    })

    const hyvaksytaHuonekortti = (id: number) => {
        mutate(id)
    }

    return {
        response,
        error,
        loading,
        hyvaksytaHuonekortti,
    }
}

export function useHyvaksyHuonekortti() {
    const queryClient = useQueryClient()
    const {
        mutate,
        error,
        isPending: loading,
        data: response,
    } = useMutation({
        mutationFn: (id: number) => http.post(`huonekortit/${id}/hyvaksy`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
        },
    })

    const hyvaksyHuonekortti = (id: number) => {
        mutate(id)
    }

    return {
        response,
        error,
        loading,
        hyvaksyHuonekortti,
    }
}

export function useHylkaaHuonekortti() {
    const queryClient = useQueryClient()
    const {
        mutate,
        error,
        isPending: loading,
        data: response,
    } = useMutation({
        mutationFn: (id: number) => http.post(`huonekortit/${id}/hylkaa`),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
        },
    })

    const hylkaaHuonekortti = (id: number) => {
        mutate(id)
    }

    return {
        response,
        error,
        loading,
        hylkaaHuonekortti,
    }
}

export function useTuoHuonekortit() {
    const queryClient = useQueryClient()

    const {
        mutate,
        error,
        isPending: loading,
        data: response,
    } = useMutation({
        mutationFn: (request: {id: number, huonekortit: HuonekorttiId[]}) => {
            return http.post(`vastuuyksikot/${request.id}/tuo`, request.huonekortit)
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["huonekortit"] })
        },
    })

    const importHuonekortit = (id: number, huonekortit: HuonekorttiId[]) => {
        mutate({id: id, huonekortit: huonekortit})
    }

    return {
        response,
        error,
        loading,
        importHuonekortit,
    }
}
