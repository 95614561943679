import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import { useHankeDataByVastuuyksikko } from "@/methods/hankeComposables"
import { useHuonekortti } from "@/methods/huonekorttiComposablses"
import { useOikeudet } from "@/methods/kayttajaOikeusComposables"
import { useCountKommentit } from "@/methods/kommenttiComposables"
import { useCountLiitteet } from "@/methods/liiteComposables"
import { useNimikeKategoria } from "@/methods/nimikkeetComposables"
import HuonekorttiEdited from "@/types/HuonekorttiEdited"
import kommenttiTyyppi from "@/types/Kommentti"
import { computed, onBeforeUnmount, ref, toRef, watch, watchEffect } from "vue"
import { NavigationGuardNext, onBeforeRouteLeave, onBeforeRouteUpdate, useRoute } from "vue-router"

interface state {
    edited: HuonekorttiEdited,
}
enum drawerEnum {
    hanketiedot,
    historia,
    liitteet,
    kommentointi
}

const drawerVisible = ref<drawerEnum>()
const kommenttiTaiLiiteViite = ref<number>()
const kommenttiTaiLiiteTyyppi = ref<kommenttiTyyppi>()
const state = ref<state>({
    edited: {
        nimikeLisays: false,
        suunnittelutiedot: false,
        toimintatiedot: false,
    },
})

export function huonekorttiPage() {
    const route = useRoute()
    const id = computed(() => Number(route.params.huonekorttiId))
    if (!id.value) {
        throw new Error("Huonekortti id:tä ei ole määritetty.")
    }
    const {
        huonekortti,
        vastuuyksikko,
        tilatString,
        loading,
    } = useHuonekortti(id)
    const vastuuyksikkoId = computed(() => huonekortti.value?.vastuuyksikko.id)
    const { hanke } = useHankeDataByVastuuyksikko(vastuuyksikkoId)
    const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
    const { writableVastuuyksikot, paakayttajaHankkeet, superUser } = useOikeudet()
    const viite = computed(() => [id.value])
    const { count: countKommentit } = useCountKommentit(viite, toRef(1 as kommenttiTyyppi))
    const { count: countLiitteet } = useCountLiitteet(viite, toRef(1 as kommenttiTyyppi))
    const countKommentti = ref<number>()
    const countLiite = ref<number>()
    const kirjoitus = ref(false)
    const paakayttaja = ref(false)
    const isEdited = ref(false)
    const isHyvaksytty = computed(() => huonekortti.value?.status == HuonekorttiStatusResponse.Hyvaksytty)
    const headerTitle = computed(() => huonekortti.value?.tilanimike?.nimi ?? "")
    const headerSubtitle = computed(
        () => (huonekortti.value?.toiminta ?? "") + " (" + tilatString.value + ")"
    )
    const drawerTitle = computed(() => {
        switch (drawerVisible.value) {
            case drawerEnum.hanketiedot:
                return "Hanketiedot"
            case drawerEnum.historia:
                return "Historia"
            case drawerEnum.liitteet:
                return "Liitteet"
            case drawerEnum.kommentointi:
                return "Kommentointi"
            default:
                return ""
        }
    })

    onBeforeRouteLeave((to, from, next) => pageChangeConfirm(next))
    onBeforeRouteUpdate((to, from, next) => pageChangeConfirm(next))
    onBeforeUnmount(() => {
        if (isEdited.value) {
            resetEdited()
        }
    })

    const setEdited = (key: keyof HuonekorttiEdited, value: boolean) => {
        state.value.edited[key] = value
    }
    const resetEdited = () => {
        state.value.edited = {
            nimikeLisays: false,
            suunnittelutiedot: false,
            toimintatiedot: false,
        }

        window.removeEventListener("beforeunload", beforeWindowUnload)
    }
    const pageChangeConfirm = (next: NavigationGuardNext) => {
        if (!isEdited.value) {
            return next()
        } else {
            const confirm = window.confirm(
                "Sinulla on tallentamattomia muutoksia, jotka katoavat jos siirryt sivulta pois. Haluatko jatkaa?"
            )

            if (confirm) {
                resetEdited()
            }

            return next(confirm)
        }
    }
    const beforeWindowUnload = (e: BeforeUnloadEvent) => {
        e.preventDefault()

        return ""
    }
    const showKommentit = (viite: number, tyyppi: kommenttiTyyppi) => {
        drawerVisible.value = drawerEnum.kommentointi
        kommenttiTaiLiiteViite.value = viite 
        kommenttiTaiLiiteTyyppi.value = tyyppi
    }
    const showLiitteet = (viite: number, tyyppi: kommenttiTyyppi) => {
        drawerVisible.value = drawerEnum.liitteet
        kommenttiTaiLiiteViite.value = viite
        kommenttiTaiLiiteTyyppi.value = tyyppi
    }

    watch(isEdited, () => {
        if (isEdited.value) {
            window.addEventListener("beforeunload", beforeWindowUnload)
        } else {
            window.removeEventListener("beforeunload", beforeWindowUnload)
        }
    }, { immediate: true })
    watch(id, () => {
        if (isEdited.value) {
            resetEdited()
        }
    }, { immediate: true })
    watch(countKommentit, () => {
        countKommentti.value = countKommentit.value?.find((v) => v.id == id.value)?.count ?? 0
    }, { immediate: true })
    watch(countLiitteet, () => {
        countLiite.value = countLiitteet.value?.find((v) => v.id == id.value)?.count ?? 0
    }, { immediate: true })
    watchEffect(() => {
        kirjoitus.value = superUser.value || writableVastuuyksikot.value?.some((v) => v == vastuuyksikko.value?.id)
        paakayttaja.value = superUser.value || paakayttajaHankkeet.value?.some((h) => h === hanke.value?.id)
    })
    watchEffect(() => {
        isEdited.value = state.value.edited.nimikeLisays || state.value.edited.suunnittelutiedot || state.value.edited.toimintatiedot
    })

    return {
        huonekortti,
        headerTitle,
        headerSubtitle,
        drawerVisible,
        hanke,
        loading,
        nimikeKategoriat,
        isEdited,
        isHyvaksytty,
        kirjoitus,
        paakayttaja,
        drawerEnum,
        drawerTitle,
        kommenttiTaiLiiteTyyppi,
        kommenttiTaiLiiteViite,
        countKommentti,
        countLiite,
        setEdited,
        showKommentit,
        showLiitteet
    }
}
