<template>
    <el-dropdown class="huonekortti-info-dropdown">
        <ComMoreButton />
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item @click="tulostaClick">Tulosta</el-dropdown-item>
                <el-dropdown-item @click="drawerVisible = drawerEnum.hanketiedot">Tiedot</el-dropdown-item>
                <el-dropdown-item v-if="isHyvaksytty" @click="drawerVisible = drawerEnum.historia">Historia</el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script setup lang="ts">
import raportointiService from "@/methods/raportointiService"
import ComMoreButton from "../common/buttons/ComMoreButton.vue"
import { huonekorttiPage } from "@/views/huonekortti"
import { useSuunnittelutiedot } from "./suunnittelutiedot"
import { computed, toRef } from "vue"
import { useCountLiitteet } from "@/methods/liiteComposables"
import kommenttiTyyppi from "@/types/Kommentti"
import { ElMessageBox } from "element-plus"

const { isHyvaksytty, huonekortti, drawerVisible, drawerEnum } = huonekorttiPage()
const id = computed(() => huonekortti.value?.id)
const countId = computed(() => id.value ? [id.value] : undefined)
const loadHuonekorttiRaportti = raportointiService.loadHuonekorttiRaportti
const loadHuonekorttiLiitteet = raportointiService.loadHuonekorttiLiitteet
const { liitteetCount } = useSuunnittelutiedot()
const { count: countHuonekorttiLiitteet } = useCountLiitteet(countId, toRef(1 as kommenttiTyyppi))
const hasLiite = computed(() => 
    countHuonekorttiLiitteet.value?.some(h => h.count > 0) || 
    liitteetCount.value?.some(l => l.count > 0))


const tulostaClick = () => {
    if (!id.value) {
        return 
    }
    const idArray = [id.value]

    if(hasLiite.value) {
        ElMessageBox.confirm("Liitteet latautuvat erillisenä zip-tiedostona. Tarkista selaimen lataukset.", "Haluatko ladata myös liitteet?", {
            confirmButtonText: "Kyllä",
            cancelButtonText: "Ei",
            type: "warning",
        }).then(() => {
            loadHuonekorttiLiitteet(idArray)
        }).catch(() => {})
        .finally(() => {
            loadHuonekorttiRaportti(1, idArray)
        })
    } else {
        loadHuonekorttiRaportti(1, idArray)
    }
}

</script>

<style scoped>
.huonekortti-info-dropdown {
    cursor: pointer;
}
</style>
