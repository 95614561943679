<template>
    <h3 style="margin-top: 0px">Lisää kalustepaketti</h3>
    <el-alert type="info" title="Voit muokata ja poistaa kalustepaketin nimikkeitä ennen lisäystä." />
    <el-table
        v-loading.fullscreen.lock="loading"
        :data="_kalustepaketti?.nimikkeet"
        style="width: 100%">
        <el-table-column
            prop="nimike.nimi"
            label="Nimi">
        </el-table-column>
        <el-table-column
            prop="maara"
            label="Kpl"
            width="65">
            <template #default="{ row }">
                <el-input 
                    v-if="editedId === row.nimike.id" 
                    v-model="editedNimike.maara" 
                    size="small" 
                    type="number"
                    style="width: 100%">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column
            prop="tasmennys"
            label="Täsmennys">
            <template #default="{ row }">
                <el-input 
                    v-if="editedId === row.nimike.id" 
                    v-model="editedNimike.tasmennys" 
                    size="small" 
                    type="textarea"
                    style="width: 100%">
                </el-input>
            </template>
        </el-table-column>
        <el-table-column>
            <template #default="{ row }">
                <template v-if="editedId === row.nimike.id">
                    <el-select 
                        v-if="nimike && editedNimike" 
                        v-model="editedNimike.ominaisuudet" 
                        value-key="id"
                        multiple 
                        size="small"
                        collapse-tags 
                        placeholder="Valitse ominaisuudet" 
                        style="width: 100%">
                        <el-option
                            v-for="ominaisuus in nimike.ominaisuudet"
                            :key="ominaisuus.id"
                            :label="ominaisuus.nimi"
                            :value="ominaisuus">
                        </el-option>
                    </el-select>
                </template>
                <template v-else v-for="ominaisuus in row.ominaisuudet" :key="ominaisuus.id">
                    <el-tag size="small">
                        {{ ominaisuus.nimi }}
                    </el-tag>
                </template>
            </template>
        </el-table-column>
        <el-table-column
            width="90">
            <template #default="{ row }">
                <template v-if="editedId === row.nimike.id">
                    <ComSaveButton @click="saveEdit" />
                    <ComCancelIconButton @click="cancelEdit" />
                </template>
                <template v-else>
                    <ComEditButton @click="editNimike(row)" />
                    <ComDeleteButton @click="deleteNimike(row)" />
                </template>
            </template>
        </el-table-column>
    </el-table>
    <el-divider border-style="none" />
    <el-row style="justify-self: end;">
        <el-col :span="6" >
            <el-space>
                <ComSuccessButton @click="saveKalustepaketti">Lisää kalustepaketti</ComSuccessButton>
                <ComCancelButton @click="emits('close')">Peruuta</ComCancelButton>
            </el-space>
        </el-col>
    </el-row>
</template>



<script setup lang="ts">
import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';
import { HuonekorttiNimikeWithoutIdResponse } from '@/api/models/huonekortti-nimike-without-id-response';
import { KalustepakettiResponse } from '@/api/models/kalustepaketti-response';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import ComCancelIconButton from '@/components/common/buttons/ComCancelIconButton.vue';
import ComDeleteButton from '@/components/common/buttons/ComDeleteButton.vue';
import ComEditButton from '@/components/common/buttons/ComEditButton.vue';
import ComSaveButton from '@/components/common/buttons/ComSaveButton.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import { useNimikeetAdd } from '@/methods/huonekorttiComposablses';
import { useNimike } from '@/methods/nimikkeetComposables';
import { huonekorttiPage } from '@/views/huonekortti';
import { ElMessage } from 'element-plus';
import _ from 'lodash';
import { ref, watch } from 'vue';

defineOptions({ inheritAttrs: false })
const props = defineProps<{ kalustepaketti: KalustepakettiResponse }>()
const emits = defineEmits(["close"])

const editedId = ref<number>()
const editedNimike = ref<HuonekorttiNimikeWithoutIdResponse>({} as HuonekorttiNimikeWithoutIdResponse)
const { nimike } = useNimike(editedId)
const { huonekortti } = huonekorttiPage()
const { addNimikkeet, response, loading } = useNimikeetAdd()
const _kalustepaketti = ref<KalustepakettiResponse>()

const editNimike = (nimike : HuonekorttiNimikeWithoutIdResponse) => {
    if(!nimike.nimike) {
        return
    }

    editedId.value = nimike.nimike.id
    editedNimike.value = _.cloneDeep(nimike)
}
const cancelEdit = () => {
    editedId.value = undefined
    editedNimike.value = {} as HuonekorttiNimikeWithoutIdResponse
}
const saveEdit = () => {
    if(!editedNimike.value || !_kalustepaketti.value?.nimikkeet) {
        return
    }

    const n = _kalustepaketti.value.nimikkeet.find((n) => n.nimike?.id === editedId.value)

    if(n) {
        n.maara = editedNimike.value.maara
        n.tasmennys = editedNimike.value.tasmennys
        n.ominaisuudet = editedNimike.value.ominaisuudet 
    }

    cancelEdit()
}
const deleteNimike = (nimike : HuonekorttiNimikeResponse) => {
    if(!_kalustepaketti.value?.nimikkeet) {
        return
    }

    _kalustepaketti.value.nimikkeet = _kalustepaketti.value.nimikkeet.filter((n) => n.nimike?.id !== nimike.nimike?.id)
}
const saveKalustepaketti = () => {
    if(!huonekortti.value?.id || !_kalustepaketti.value?.nimikkeet) {
        return
    }

    const nimikkeet = _kalustepaketti.value.nimikkeet.map((n) => {
        return {
            nimike: {
                id: n.nimike?.id,
            },
            maara: n.maara,
            tasmennys: n.tasmennys,
            ominaisuudet: n.ominaisuudet?.map((o) => {
                return { id: o.id }
            }),
        } as HuonekorttiNimikeResponse
    })

    addNimikkeet(huonekortti.value.id, nimikkeet)
}

watch(loading, (value) => {
    if (!value && response.value) {
        ElMessage.success("Kalustepaketti lisätty")
        emits('close')
    }
})

watch(() => props.kalustepaketti, (value) => {
    _kalustepaketti.value = _.cloneDeep(value)
}, { immediate: true })

</script>