import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  style: {"width":"100%"}
}
const _hoisted_4 = { style: {"float":"left"} }
const _hoisted_5 = {
  key: 0,
  style: {"float":"right","padding-left":"10px"}
}

import { computed, ref, toRef } from 'vue';
import { useHakuComponent } from './haku';
import { setNodes, useNimikeKategoria } from '@/methods/nimikkeetComposables';
import ComHakuSuunnittelutiedotOminaisuudet from './ComHakuSuunnittelutiedotOminaisuudet.vue';
import ComPlusButton from '@/components/common/buttons/ComPlusButton.vue';
import ComMinusButton from '@/components/common/buttons/ComMinusButton.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHakuSuunnittelutiedot',
  setup(__props) {

const { searchForm, selectedNodes, isSelected, showHeading } = useHakuComponent()
const suunnittelutiedot = computed(() => searchForm.value.nimike)
const { nimikeKategoriat } = useNimikeKategoria(toRef(true))
const showTiedot = ref<number[]>([])
const nodes = computed(() => setNodes(nimikeKategoriat.value ?? [])) 

const isSelectedNimike = (id : number) => {
    return showTiedot.value?.find(s => s === id) ? true : false
}

const removeFromSelected = (id : number) => {
    showTiedot.value = showTiedot.value.filter(s => s !== id)
}

const cascaderFocus = (data : {disabled: boolean, value: number, label: string}) => {
    if(!isSelectedNimike(data.value)) {
        showTiedot.value.push(data.value)
    }
    else {
        removeFromSelected(data.value)
        searchForm.value.nimike?.find((n, i) => {
            if(n.id === data.value && searchForm.value.nimike) {
                searchForm.value.nimike[i] = { id: data.value }
            }
        })
    }
}

const show = (id : number | undefined) => {
    return showTiedot.value?.find(s => s === id) ? true : false
}

const selectionChange = (val : number[][]) => {
    if(val.length === 0) {
        searchForm.value.nimike = []
        showTiedot.value = []
    } else {
        searchForm.value.nimike?.forEach((n, i) => {
            if(!val.find(v => v[2] === n.id) && searchForm.value.nimike) {
                delete searchForm.value.nimike[i]

                removeFromSelected(i)
            }
        })

        searchForm.value.nimike = searchForm.value.nimike?.filter(Boolean)

        val.forEach(v => {
            if(!searchForm.value.nimike?.find(n => n.id === v[2])) {
                searchForm.value.nimike?.push({ id: v[2] })
            }
        })
    }
}


return (_ctx: any,_cache: any) => {
  const _component_el_cascader_panel = _resolveComponent("el-cascader-panel")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(showHeading)(1))
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, "Suunnittelutiedot"))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form, {
      model: suunnittelutiedot.value,
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
      ref: "form",
      inline: false,
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            (_unref(isSelected)('nimike'))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_el_col, { span: 14 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_cascader_panel, {
                            options: nodes.value,
                            props: {
                            disabled: false,
                            multiple: true,
                        },
                            size: "small",
                            modelValue: _unref(selectedNodes),
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(selectedNodes) ? (selectedNodes).value = $event : null)),
                            style: {"width":"100%"},
                            onChange: selectionChange
                          }, {
                            default: _withCtx(({ node, data }) => [
                              (!node.isLeaf)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(data.label), 1))
                                : _createCommentVNode("", true),
                              (node.isLeaf)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                    _createElementVNode("div", _hoisted_4, _toDisplayString(data.label), 1),
                                    (node.checked)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                          (!isSelectedNimike(data.value))
                                            ? (_openBlock(), _createBlock(ComPlusButton, {
                                                key: 0,
                                                onClick: ($event: any) => (cascaderFocus(data))
                                              }, null, 8, ["onClick"]))
                                            : _createCommentVNode("", true),
                                          (isSelectedNimike(data.value))
                                            ? (_openBlock(), _createBlock(ComMinusButton, {
                                                key: 1,
                                                onClick: ($event: any) => (cascaderFocus(data))
                                              }, null, 8, ["onClick"]))
                                            : _createCommentVNode("", true)
                                        ]))
                                      : _createCommentVNode("", true)
                                  ]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["options", "modelValue"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  (showTiedot.value.length > 0)
                    ? (_openBlock(), _createBlock(_component_el_col, {
                        key: 0,
                        span: 10
                      }, {
                        default: _withCtx(() => [
                          _cache[2] || (_cache[2] = _createElementVNode("h3", null, "Tarkenna nimikkeiden haku termejä", -1)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(searchForm).nimike, (selectedNimike, i) => {
                            return (_openBlock(), _createElementBlock(_Fragment, {
                              key: selectedNimike.id
                            }, [
                              (show(selectedNimike.id) && _unref(searchForm).nimike)
                                ? (_openBlock(), _createBlock(ComHakuSuunnittelutiedotOminaisuudet, {
                                    key: 0,
                                    show: show(selectedNimike.id),
                                    modelValue: _unref(searchForm).nimike[i],
                                    "onUpdate:modelValue": ($event: any) => ((_unref(searchForm).nimike[i]) = $event)
                                  }, null, 8, ["show", "modelValue", "onUpdate:modelValue"]))
                                : _createCommentVNode("", true)
                            ], 64))
                          }), 128))
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ], 64))
}
}

})