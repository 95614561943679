import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding-left":"0px"} }

import { computed, ref } from "vue"
import ComHuonekorttiNimikeHistoriaItem from "./ComHuonekorttiNimikeHistoriaItem.vue"
import ComInfoButton from "@/components/common/buttons/ComInfoButton.vue"
import { useHuonekorttiHistoria, useHuonekorttiHistoriaMethods } from "@/methods/huonekorttiHistoriaComposables"
import { huonekorttiPage } from "@/views/huonekortti"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNimikeHistoriaPopover',
  props: {
    id: {}
  },
  setup(__props: any) {

const props = __props

const { huonekortti } = huonekorttiPage()
const huonekorttiId = computed(() => huonekortti.value?.id)
const { historia } = useHuonekorttiHistoria(huonekorttiId)
const { diffAllHistoriaNimike, tyyppiColor: buttonColor } = useHuonekorttiHistoriaMethods()
const visible = ref(false)
const active = ref(0)

const historiaNimikkeet = computed(() => {
    if (visible.value) {
        const hn = historia.value?.nimikkeet
        const nimike = huonekortti?.value?.nimikkeet?.find((n) => n.id == props.id)

        if (hn) {
            return diffAllHistoriaNimike(hn, props.id, nimike)
        }
    }

    return []
})

return (_ctx: any,_cache: any) => {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_popover = _resolveComponent("el-popover")!

  return (_openBlock(), _createBlock(_component_el_popover, {
    trigger: "click",
    placement: "left",
    width: "800px",
    visible: visible.value,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((visible).value = $event))
  }, {
    reference: _withCtx(() => [
      _createVNode(ComInfoButton)
    ]),
    default: _withCtx(() => [
      (visible.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(ComHuonekorttiNimikeHistoriaItem, {
              data: historiaNimikkeet.value[active.value]
            }, null, 8, ["data"]),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createElementVNode("ul", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(historiaNimikkeet.value, (historia, index) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: historia.id,
                      style: {"display":"inline-block"}
                    }, [
                      _createVNode(_component_el_button, {
                        type: _unref(buttonColor)(historia.tyyppi),
                        onClick: ($event: any) => (active.value = index),
                        text: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(historia.aika), 1)
                        ]),
                        _: 2
                      }, 1032, ["type", "onClick"])
                    ]))
                  }), 128))
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (visible.value = false))
                    }, {
                      default: _withCtx(() => _cache[2] || (_cache[2] = [
                        _createTextVNode("Sulje")
                      ])),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible"]))
}
}

})