import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

import { HuonekorttiNimikeResponse } from '@/api/models/huonekortti-nimike-response';
import { HuonekorttiNimikeWithoutIdResponse } from '@/api/models/huonekortti-nimike-without-id-response';
import { KalustepakettiResponse } from '@/api/models/kalustepaketti-response';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import ComCancelIconButton from '@/components/common/buttons/ComCancelIconButton.vue';
import ComDeleteButton from '@/components/common/buttons/ComDeleteButton.vue';
import ComEditButton from '@/components/common/buttons/ComEditButton.vue';
import ComSaveButton from '@/components/common/buttons/ComSaveButton.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';
import { useNimikeetAdd } from '@/methods/huonekorttiComposablses';
import { useNimike } from '@/methods/nimikkeetComposables';
import { huonekorttiPage } from '@/views/huonekortti';
import { ElMessage } from 'element-plus';
import _ from 'lodash';
import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  ...{ inheritAttrs: false },
  __name: 'ComKalustepakketti',
  props: {
    kalustepaketti: {}
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {


const props = __props
const emits = __emit

const editedId = ref<number>()
const editedNimike = ref<HuonekorttiNimikeWithoutIdResponse>({} as HuonekorttiNimikeWithoutIdResponse)
const { nimike } = useNimike(editedId)
const { huonekortti } = huonekorttiPage()
const { addNimikkeet, response, loading } = useNimikeetAdd()
const _kalustepaketti = ref<KalustepakettiResponse>()

const editNimike = (nimike : HuonekorttiNimikeWithoutIdResponse) => {
    if(!nimike.nimike) {
        return
    }

    editedId.value = nimike.nimike.id
    editedNimike.value = _.cloneDeep(nimike)
}
const cancelEdit = () => {
    editedId.value = undefined
    editedNimike.value = {} as HuonekorttiNimikeWithoutIdResponse
}
const saveEdit = () => {
    if(!editedNimike.value || !_kalustepaketti.value?.nimikkeet) {
        return
    }

    const n = _kalustepaketti.value.nimikkeet.find((n) => n.nimike?.id === editedId.value)

    if(n) {
        n.maara = editedNimike.value.maara
        n.tasmennys = editedNimike.value.tasmennys
        n.ominaisuudet = editedNimike.value.ominaisuudet 
    }

    cancelEdit()
}
const deleteNimike = (nimike : HuonekorttiNimikeResponse) => {
    if(!_kalustepaketti.value?.nimikkeet) {
        return
    }

    _kalustepaketti.value.nimikkeet = _kalustepaketti.value.nimikkeet.filter((n) => n.nimike?.id !== nimike.nimike?.id)
}
const saveKalustepaketti = () => {
    if(!huonekortti.value?.id || !_kalustepaketti.value?.nimikkeet) {
        return
    }

    const nimikkeet = _kalustepaketti.value.nimikkeet.map((n) => {
        return {
            nimike: {
                id: n.nimike?.id,
            },
            maara: n.maara,
            tasmennys: n.tasmennys,
            ominaisuudet: n.ominaisuudet?.map((o) => {
                return { id: o.id }
            }),
        } as HuonekorttiNimikeResponse
    })

    addNimikkeet(huonekortti.value.id, nimikkeet)
}

watch(loading, (value) => {
    if (!value && response.value) {
        ElMessage.success("Kalustepaketti lisätty")
        emits('close')
    }
})

watch(() => props.kalustepaketti, (value) => {
    _kalustepaketti.value = _.cloneDeep(value)
}, { immediate: true })


return (_ctx: any,_cache: any) => {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("h3", { style: {"margin-top":"0px"} }, "Lisää kalustepaketti", -1)),
    _createVNode(_component_el_alert, {
      type: "info",
      title: "Voit muokata ja poistaa kalustepaketin nimikkeitä ennen lisäystä."
    }),
    _withDirectives((_openBlock(), _createBlock(_component_el_table, {
      data: _kalustepaketti.value?.nimikkeet,
      style: {"width":"100%"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "nimike.nimi",
          label: "Nimi"
        }),
        _createVNode(_component_el_table_column, {
          prop: "maara",
          label: "Kpl",
          width: "65"
        }, {
          default: _withCtx(({ row }) => [
            (editedId.value === row.nimike.id)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: editedNimike.value.maara,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((editedNimike.value.maara) = $event)),
                  size: "small",
                  type: "number",
                  style: {"width":"100%"}
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "tasmennys",
          label: "Täsmennys"
        }, {
          default: _withCtx(({ row }) => [
            (editedId.value === row.nimike.id)
              ? (_openBlock(), _createBlock(_component_el_input, {
                  key: 0,
                  modelValue: editedNimike.value.tasmennys,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((editedNimike.value.tasmennys) = $event)),
                  size: "small",
                  type: "textarea",
                  style: {"width":"100%"}
                }, null, 8, ["modelValue"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, null, {
          default: _withCtx(({ row }) => [
            (editedId.value === row.nimike.id)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (_unref(nimike) && editedNimike.value)
                    ? (_openBlock(), _createBlock(_component_el_select, {
                        key: 0,
                        modelValue: editedNimike.value.ominaisuudet,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((editedNimike.value.ominaisuudet) = $event)),
                        "value-key": "id",
                        multiple: "",
                        size: "small",
                        "collapse-tags": "",
                        placeholder: "Valitse ominaisuudet",
                        style: {"width":"100%"}
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(nimike).ominaisuudet, (ominaisuus) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: ominaisuus.id,
                              label: ominaisuus.nimi,
                              value: ominaisuus
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"]))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(row.ominaisuudet, (ominaisuus) => {
                  return (_openBlock(), _createBlock(_component_el_tag, {
                    key: ominaisuus.id,
                    size: "small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(ominaisuus.nimi), 1)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, { width: "90" }, {
          default: _withCtx(({ row }) => [
            (editedId.value === row.nimike.id)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(ComSaveButton, { onClick: saveEdit }),
                  _createVNode(ComCancelIconButton, { onClick: cancelEdit })
                ], 64))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(ComEditButton, {
                    onClick: ($event: any) => (editNimike(row))
                  }, null, 8, ["onClick"]),
                  _createVNode(ComDeleteButton, {
                    onClick: ($event: any) => (deleteNimike(row))
                  }, null, 8, ["onClick"])
                ], 64))
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"])), [
      [
        _directive_loading,
        _unref(loading),
        void 0,
        {
          fullscreen: true,
          lock: true
        }
      ]
    ]),
    _createVNode(_component_el_divider, { "border-style": "none" }),
    _createVNode(_component_el_row, { style: {"justify-self":"end"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_space, null, {
              default: _withCtx(() => [
                _createVNode(ComSuccessButton, { onClick: saveKalustepaketti }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Lisää kalustepaketti")
                  ])),
                  _: 1
                }),
                _createVNode(ComCancelButton, {
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (emits('close')))
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode("Peruuta")
                  ])),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}
}

})