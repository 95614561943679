import { computed, ref, watch } from "vue"
import raportointiService from "@/methods/raportointiService"
import huonekorttiTableStore from "../huonekortit/table/huonekorttiTableStore"
import { ElMessage } from "element-plus"
import { hankePage } from "@/views/hanke"

export function raportointi() {
    const loadHuonekorttiRaportti = raportointiService.loadHuonekorttiRaportti
    const loadHistoriaRaportti = raportointiService.loadHistoriaRaportti
    const loadTilaRaportti = raportointiService.loadTilaRaportti
    const loadNimikeRaportti = raportointiService.loadNimikeRaportti
    const loadHuonekorttiLiitteet = raportointiService.loadHuonekorttiLiitteet
    const LiitteetEmpty = computed(() => raportointiService.state.LiitteetEmpty)
    const getSelectedHuonekortit = huonekorttiTableStore.getters.getSelected
    const setSelectedHuonekortit = huonekorttiTableStore.methods.setSelected
    const loadingRaportti = computed(() => raportointiService.state.loading || raportointiService.state.loadingLiiteet)
    const tyyppi = ["Huonekortti", "Tila", "Historia", "Nimike"]
    const valittu = ref("")
    const historiaRange = ref<string[]>()
    const huonekorteittain = ref(false)
    const nimikkeet = ref<number[]>()
    const { selectedVastuuyksikkoIds } = hankePage()
    const liitteet = ref(false)

    const tulosta = (val: number) => {
        if (valittu.value == "Huonekortti") {
            if (anyHuonekorttiChecked()) {
                loadHuonekorttiRaportti(val, getSelectedHuonekortit())
                if(liitteet.value) {
                    loadHuonekorttiLiitteet(getSelectedHuonekortit())
                }
            }
        }
        if (valittu.value == "Historia") {
            if (anyHuonekorttiChecked() && historiaRange.value?.length == 2) {
                loadHistoriaRaportti(val, getSelectedHuonekortit(), historiaRange.value[0], historiaRange.value[1])
            } else if (historiaRange.value && historiaRange.value?.length > 2) {
                ElMessage({
                    message: "Valitse raportoitava aikaväli!",
                    type: "error",
                })
            }
        }
        if (valittu.value == "Tila") {
            if (huonekorteittain.value) {
                if (anyHuonekorttiChecked()) {
                    loadTilaRaportti(val, undefined, getSelectedHuonekortit())
                }
            } else {
                if (selectedVastuuyksikkoIds.value && selectedVastuuyksikkoIds.value?.length > 0) {
                    loadTilaRaportti(val, selectedVastuuyksikkoIds.value, undefined)
                } else {
                    ElMessage({
                        message: "Valitse raportoitava vastuuyksikkö!",
                        type: "error",
                    })
                }
            }
        }
        if (valittu.value == "Nimike") {
            if (anyNimikeChecked()) {
                if (huonekorteittain.value) {
                    if (anyHuonekorttiChecked()) {
                        loadNimikeRaportti(nimikkeet.value!, undefined, getSelectedHuonekortit())
                    }
                } else {
                    if (selectedVastuuyksikkoIds.value && selectedVastuuyksikkoIds.value?.length > 0) {
                        loadNimikeRaportti(nimikkeet.value!, selectedVastuuyksikkoIds.value)
                    } else {
                        ElMessage({
                            message: "Valitse raportoitava vastuuyksikkö!",
                            type: "error",
                        })
                    }
                }
            }
        }
    }

    const anyHuonekorttiChecked = () => {
        if (getSelectedHuonekortit().length > 0) {
            return true
        } else {
            ElMessage({
                message: "Valitse raportoitava kohde!",
                type: "error",
            })

            return false
        }
    }

    const anyNimikeChecked = () => {
        if (nimikkeet.value && nimikkeet.value.length > 0) {
            return true
        } else {
            ElMessage({
                message: "Valitse vähintään yksi raportoitava nimike!",
                type: "error",
            })

            return false
        }
    }

    watch(LiitteetEmpty, (value) => {
        if (value) {
            ElMessage.info({duration: 10000, message: "Valituille kohteille ei ole liitteitä"})
        }
    })
    watch(selectedVastuuyksikkoIds, () => {
        setSelectedHuonekortit([])
    })

    return {
        loadHuonekorttiRaportti,
        loadHistoriaRaportti,
        loadTilaRaportti,
        loadNimikeRaportti,
        getSelectedHuonekortit,
        tulosta,
        liitteet,
        loadingRaportti,
        tyyppi,
        valittu,
        historiaRange,
        huonekorteittain,
        nimikkeet,
    }
}