import { Note } from "@/types/Note";
import { useQuery } from "@tanstack/vue-query";

export function useNotes() {
    const { data: notes, error, isLoading: loading} = useQuery({
        queryKey: ["notes"],
        queryFn: async () => {
            const response = await fetch("/release-notes.json");
            return response.json() as Promise<Note[]>;
        }
    })

    return {
        notes,
        error,
        loading,
    }
}