interface Note {
    id: number,
    date: string,
    type: string,
    tags: string[],
    title: string,
    description: string,
    link: string,
}

enum NoteType {
    "ench",
    "fix",
    "perf",
    "info"
}

export { Note, NoteType }