import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-header huonekortti-historia-item-header" }
const _hoisted_2 = { style: {"width":"100%"} }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { colspan: "4" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { colspan: "4" }
const _hoisted_7 = ["innerHTML"]

import { HuonekorttiHistoriaTyyppi } from "@/api/models/huonekortti-historia-tyyppi"
import { computed } from "vue"
import HistoriaNimike from "@/types/HistoriaNimike"
import ComCard from "@/components/common/ComCard.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComHuonekorttiNimikeHistoriaItem',
  props: {
    data: {}
  },
  setup(__props: any) {

const props = __props
const item = computed(() => {
    const item = {
        otsikko: "",
        sisalto: "",
    }
    const nimike = props.data.nimike?.nimi?.toLowerCase()
    if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Lisays) {
        item.otsikko = `Nimike ${nimike} lisätty`
    }
    if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Poisto) {
        item.otsikko = `Nimike ${nimike} poistettu`
    }
    if (props.data.tyyppi == HuonekorttiHistoriaTyyppi.Muutos) {
        item.otsikko = `Nimikettä ${nimike} muokattu`
    }
    return item
})
const ominaisuudet = computed(() => {
    return props.data.ominaisuudet?.map((o) => o.nimi).join(", ")
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ComCard, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(item.value.otsikko), 1),
      _createElementVNode("table", _hoisted_2, [
        _createElementVNode("tbody", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, [
              _cache[0] || (_cache[0] = _createElementVNode("strong", null, "Tekijä:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.data?.kayttaja?.etunimi) + " " + _toDisplayString(_ctx.data?.kayttaja?.sukunimi), 1)
            ]),
            _createElementVNode("td", null, [
              _cache[1] || (_cache[1] = _createElementVNode("strong", null, "Aika:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.data.aika), 1)
            ])
          ]),
          _createElementVNode("tr", null, [
            _createElementVNode("td", null, [
              _cache[2] || (_cache[2] = _createElementVNode("strong", null, "Nimike:", -1)),
              _createTextVNode(" " + _toDisplayString(_ctx.data.nimike?.nimi), 1)
            ]),
            _createElementVNode("td", null, [
              _cache[3] || (_cache[3] = _createElementVNode("strong", null, "Määrä:", -1)),
              _createElementVNode("span", {
                innerHTML: _ctx.data.maara
              }, null, 8, _hoisted_3)
            ])
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("tr", null, [
            _createElementVNode("td", { colspan: "4" }, [
              _createElementVNode("strong", null, "Täsmennys:")
            ])
          ], -1)),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_4, [
              _createElementVNode("span", {
                innerHTML: _ctx.data.tasmennys
              }, null, 8, _hoisted_5)
            ])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("tr", null, [
            _createElementVNode("td", { colspan: "4" }, [
              _createElementVNode("strong", null, "Ominaisuudet:")
            ])
          ], -1)),
          _createElementVNode("tr", null, [
            _createElementVNode("td", _hoisted_6, [
              _createElementVNode("span", { innerHTML: ominaisuudet.value }, null, 8, _hoisted_7)
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})