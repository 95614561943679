import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

import ComEditButton from '@/components/common/buttons/ComEditButton.vue';
import { useNimikeMuokkausComponent } from './nimikeMuokkaus';
import { useHuonekorttiFormatTilat } from '@/methods/huonekorttiComposablses';
import { useNimike } from '@/methods/nimikkeetComposables';
import ComCancelIconButton from '@/components/common/buttons/ComCancelIconButton.vue';
import ComSaveButton from '@/components/common/buttons/ComSaveButton.vue';
import { Loading, Check } from '@element-plus/icons-vue';
import ComPlusButton from '@/components/common/buttons/ComPlusButton.vue';
import ComNimikeMuokkausLisaaOminaisuus from './ComNimikeMuokkausLisaaOminaisuus.vue';
import ComMinusButton from '@/components/common/buttons/ComMinusButton.vue';
import ComNimikeMuokkausPoistaOminaisuus from './ComNimikeMuokkausPoistaOminaisuus.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComNimikeMuokkausTable',
  setup(__props) {

const { nimikkeet, editedNimike, editedId, nimikeId, loading, updateSuccess, showLisaaOminaisuudet, showPoistaOminaisuudet, muokkaaNimiketta, peruutaMuokkaus, tallennaMuokkaus } = useNimikeMuokkausComponent()
const { formatTilat } = useHuonekorttiFormatTilat()
const { nimike } = useNimike(nimikeId)

return (_ctx: any,_cache: any) => {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, { data: _unref(nimikkeet) }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: "Nimike",
        prop: "nimike.nimi"
      }),
      _createVNode(_component_el_table_column, {
        label: "Tilanimike",
        prop: "huonekortti.tilanimike.nimi"
      }),
      _createVNode(_component_el_table_column, {
        label: "Tilat",
        "show-overflow-tooltip": ""
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("span", null, _toDisplayString(_unref(formatTilat)(scope.row.huonekortti.tilat)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Määrä",
        prop: "maara",
        width: "100px"
      }, {
        default: _withCtx((scope) => [
          (_unref(editedId) == scope.row.id && _unref(editedNimike))
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                modelValue: _unref(editedNimike).maara,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(editedNimike).maara) = $event)),
                size: "small",
                type: "number",
                style: {"width":"100%"}
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Täsmennys",
        prop: "tasmennys"
      }, {
        default: _withCtx((scope) => [
          (_unref(editedId) == scope.row.id && _unref(editedNimike))
            ? (_openBlock(), _createBlock(_component_el_input, {
                key: 0,
                modelValue: _unref(editedNimike).tasmennys,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(editedNimike).tasmennys) = $event)),
                size: "small",
                type: "textarea",
                style: {"width":"100%"}
              }, null, 8, ["modelValue"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, { label: "Ominaisuudet" }, {
        header: _withCtx(() => [
          (_unref(showLisaaOminaisuudet))
            ? (_openBlock(), _createBlock(_component_el_space, { key: 0 }, {
                default: _withCtx(() => [
                  _createVNode(ComNimikeMuokkausLisaaOminaisuus),
                  _createVNode(ComCancelIconButton, {
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (showLisaaOminaisuudet.value = false))
                  })
                ]),
                _: 1
              }))
            : (_unref(showPoistaOminaisuudet))
              ? (_openBlock(), _createBlock(_component_el_space, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(ComNimikeMuokkausPoistaOminaisuus),
                    _createVNode(ComCancelIconButton, {
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (showPoistaOminaisuudet.value = false))
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_el_space, { key: 2 }, {
                  default: _withCtx(() => [
                    _cache[7] || (_cache[7] = _createElementVNode("span", null, "Ominaisuudet", -1)),
                    _createVNode(ComPlusButton, {
                      size: "small",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (showLisaaOminaisuudet.value = true))
                    }),
                    _createVNode(ComMinusButton, {
                      size: "small",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (showPoistaOminaisuudet.value = true))
                    })
                  ]),
                  _: 1
                }))
        ]),
        default: _withCtx((scope) => [
          (_unref(editedId) != scope.row.id)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(scope.row.ominaisuudet, (ominaisuus, index) => {
                return (_openBlock(), _createBlock(_component_el_tag, {
                  type: "info",
                  size: "small",
                  style: {"margin-right":"2px"},
                  key: index
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", {
                      innerHTML: ominaisuus.nimi
                    }, null, 8, _hoisted_1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            : (_unref(nimike) && _unref(editedNimike))
              ? (_openBlock(), _createBlock(_component_el_select, {
                  key: 1,
                  modelValue: _unref(editedNimike).ominaisuudet,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(editedNimike).ominaisuudet) = $event)),
                  "value-key": "id",
                  multiple: "",
                  size: "small",
                  "collapse-tags": "",
                  placeholder: "Valitse ominaisuudet",
                  style: {"width":"100%"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(nimike).ominaisuudet, (ominaisuus) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: ominaisuus.id,
                        label: ominaisuus.nimi,
                        value: ominaisuus
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"]))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_el_table_column, {
        label: "Muokkaa",
        width: "100px"
      }, {
        default: _withCtx((scope) => [
          (_unref(loading))
            ? (_openBlock(), _createBlock(_component_el_icon, {
                key: 0,
                class: "is-loading"
              }, {
                default: _withCtx(() => [
                  _createVNode(_unref(Loading))
                ]),
                _: 1
              }))
            : (_unref(updateSuccess).includes(scope.row.id))
              ? (_openBlock(), _createBlock(_component_el_icon, {
                  key: 1,
                  class: "is-success"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(Check))
                  ]),
                  _: 1
                }))
              : (_unref(editedId) == scope.row.id)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(ComCancelIconButton, {
                      size: "small",
                      onClick: _unref(peruutaMuokkaus)
                    }, null, 8, ["onClick"]),
                    _createVNode(ComSaveButton, {
                      size: "small",
                      onClick: _unref(tallennaMuokkaus)
                    }, null, 8, ["onClick"])
                  ], 64))
                : (_openBlock(), _createBlock(ComEditButton, {
                    key: 3,
                    type: "primary",
                    size: "small",
                    onClick: ($event: any) => (_unref(muokkaaNimiketta)(scope.row))
                  }, null, 8, ["onClick"]))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data"]))
}
}

})