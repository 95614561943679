import { HuonekorttiRequestPut } from "@/api/models/huonekortti-request-put"
import { HuonekorttiResponse } from "@/api/models/huonekortti-response"
import { useHuonekorttiTable } from "./huonekorttiTable"
import { ElMessage, ElMessageBox, TableInstance } from "element-plus"
import { ref, toRef, watch } from "vue"
import { HuonekorttiStatusResponse } from "@/api/models/huonekortti-status-response"
import { hankePage } from "@/views/hanke"
import { useHuonekortti, useHuonekorttiUpdate } from "@/methods/huonekorttiComposablses"
import { huonekorttiListing } from "../huonekortit"
import _ from "lodash"
import { useCountKommentit } from "@/methods/kommenttiComposables"
import kommenttiTyyppi from "@/types/Kommentti"
import { useCountLiitteet } from "@/methods/liiteComposables"

export function huonekorttiTableEditable() {
    const { id, hankeVastuuyksikot, tilanimikkeet, writableVastuuyksikot, paakayttaja, resetTilanumero } = hankePage()
    const { huonekortit, sort, sortChanged, updateHuonekortit, addTila, removeTila, deleteHuonekortti } = huonekorttiListing()
    const { formatTilat } = useHuonekorttiTable()
    const edit = ref()
    const editedHuonekortti = ref<HuonekorttiRequestPut>({} as HuonekorttiRequestPut)
    const { huonekortti } = useHuonekortti(edit)
    const { updateHuonekortti, response, loading, error  } = useHuonekorttiUpdate()
    const mallihuonekortti = ref<HuonekorttiResponse>()
    const mallihuonekorttiDialog = ref(false)
    const tableRef = ref<TableInstance>()
    const huonekorttiIds = ref<number[]>()
    const { count: kommentitCount, error: kommenttiCountError } = useCountKommentit(huonekorttiIds, toRef(1 as kommenttiTyyppi))
    const { count: liiteetCount, error: liiteetCountError } = useCountLiitteet(huonekorttiIds, toRef(1 as kommenttiTyyppi))

    const checkPoistoOikeus = (val: HuonekorttiResponse) => {
        if (val.status == HuonekorttiStatusResponse.Hyvaksytty && paakayttaja) {
            return true
        } else if (
            val.status != HuonekorttiStatusResponse.Hyvaksytty &&
            writableVastuuyksikot.value?.some((v) => v?.id == val.vastuuyksikko.id)
        ) {
            return true
        } else {
            return false
        }
    }
    const editHuonekortti = (item: HuonekorttiResponse) => {
        editedHuonekortti.value = _.cloneDeep(item)
        edit.value = item.id
    }
    const cancelEdit = () => {
        updateHuonekortit()
        resetTilanumero()

        edit.value = undefined
    }
    const saveEdit = async (item: HuonekorttiResponse) => {
        if (
            item.vastuuyksikko?.id &&
            item.tilanimike?.id &&
            item.tilat &&
            item.tilat.length > 0 &&
            item.id &&
            huonekortti.value
        ) {
            editedHuonekortti.value.toiminnanKuvaus = huonekortti.value.toiminnanKuvaus
            editedHuonekortti.value.toiminnanVaatimukset = huonekortti.value.toiminnanVaatimukset
            editedHuonekortti.value.henkilomaaraNorm = huonekortti.value.henkilomaaraNorm
            editedHuonekortti.value.henkilomaaraMax = huonekortti.value.henkilomaaraMax
            editedHuonekortti.value.sahkoluokka = huonekortti.value.sahkoluokka
            editedHuonekortti.value.toimintaAika = huonekortti.value.toimintaAika

            await updateHuonekortti(item.id, editedHuonekortti.value)
        }
    }
    const deleteHuonekorttiClick = (value: HuonekorttiResponse) => {
        ElMessageBox.confirm("Haluatko varmasti poistaa huonekortin: " + value.tilanimike?.nimi).then(
            () => {
                if (value.id) {
                    deleteHuonekortti(value.id)
                }
            }
        ).catch(() => {
        })
    }
    const addMallihuonekorttiClick = (val: HuonekorttiResponse) => {
        mallihuonekortti.value = val
        mallihuonekorttiDialog.value = true
    }
    const getKommentitCount = (id: number) => {
        return kommentitCount.value?.find((v) => v.id == id)?.count
    }
    const getLiitteetCount = (id: number) => {
        return liiteetCount.value?.find((v) => v.id == id)?.count
    }

    watch(id, () => {
        if (id.value) {
            tableRef.value?.sort(sort.value.prop, sort.value.order.toString())
        }
    })
    watch(loading, (val) => {
        if(!val && response.value) {
            ElMessage({ message: "Huonekortti tallennettu!", type: "success" })

            edit.value = undefined
        }
        if(!val && error.value) {
            ElMessage({ message: "Huonekortin tallennus epäonnistui!", type: "error" })
        }
    })
    watch(huonekortit, (value) => {
        if (value) {
            huonekorttiIds.value = value.map((v) => v.id)
        }
    })
    
    return {
        huonekortit,
        edit,
        tilanimikkeet,
        hankeVastuuyksikot,
        mallihuonekorttiDialog,
        paakayttaja,
        writableVastuuyksikot,
        mallihuonekortti,
        sort,
        tableRef,
        editedHuonekortti,
        kommentitCount,
        liiteetCount,
        sortChanged,
        formatTilat,
        checkPoistoOikeus,
        addTila,
        removeTila,
        editHuonekortti,
        cancelEdit,
        saveEdit,
        addMallihuonekorttiClick,
        deleteHuonekorttiClick,
        getKommentitCount,
        getLiitteetCount
    }
}
