<template>
    <h3 v-if="showHeading(0)">Toimintatiedot</h3>
    <el-form :model="toimintatiedot" @submit.prevent ref="form" :inline="false" size="small">
        <el-row :gutter="20">
            <el-col :span="12" v-if="isSelected('tilanimike')">
                <el-form-item label="Tilanimike ">
                <el-select multiple v-model="toimintatiedot.tilanimike" placeholder="Hae tilanimikkeellä">
                    <el-option v-for="tilanimike in tilanimikkeet" :value="tilanimike.nimi" :key="tilanimike.id">{{ tilanimike.nimi }}</el-option>
                </el-select>
            </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSelected('tilanimike-tasmennys')">
                <el-form-item label="Tilanimikkeen täsmennys" size="small">
                    <el-input v-model="toimintatiedot['tilanimike-tasmennys']" placeholder="Hae tilanimikkeen täsmennyksellä" minlength="3"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSelected('toiminnan-kuvaus')">
            <el-form-item label="Toiminnan kuvaus" size="small">
                <el-input v-model="toimintatiedot['toiminnan-kuvaus']" placeholder="Hae toiminnan kuvauksella" minlength="3"></el-input>
            </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSelected('toiminnan-vaatimukset')">
                <el-form-item label="Toiminnan vaatimukset">
                    <el-input v-model="toimintatiedot['toiminnan-vaatimukset']" placeholder="Hae toiminnan vaatimuksilla" minlength="3"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSelected('henkilomaara-norm')">
                <el-form-item label="Henkiömäärä normaalisti">
                    <el-input-number v-model="toimintatiedot['henkilomaara-norm']" placeholder="Hae henkilömäärä normaalisti"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSelected('henkilomaara-max')">
                <el-form-item label="Henkilömäärä max">
                    <el-input-number v-model="toimintatiedot['henkilomaara-max']" placeholder="Hae henkilömäärä maksimi"></el-input-number>
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSelected('sahkoluokka')">
                <el-form-item label="Sähköluokka">
                    <el-checkbox-group v-model="toimintatiedot.sahkoluokka">
                        <el-checkbox-button v-for="sahkoluokka in HuonekorttiSahkoluokka" :value="sahkoluokka" :key="sahkoluokka">{{ sahkoluokka }}</el-checkbox-button>
                    </el-checkbox-group>
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="isSelected('toiminta-aika')">
                <el-form-item label="ToimintaAika">
                    <el-select allow-create v-model="toimintatiedot['toiminta-aika']" placeholder="Hae toiminta-ajalla">
                        <el-option v-for="aika in ToimintaAika" :value="aika" :key="aika">{{ aika }}</el-option>
                    </el-select>
                </el-form-item>
            </el-col>
        </el-row>
    </el-form>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useHakuComponent } from './haku';
import { HuonekorttiSahkoluokka } from '@/api/models/huonekortti-sahkoluokka';
import ToimintaAika from "@/types/ToimintaAikaEnum"
import { useTilanimikeet } from '@/methods/tilanimikeComposables';
const { searchForm, isSelected, showHeading } = useHakuComponent()
const toimintatiedot = ref(searchForm.value.huonekortti)
const { tilanimikkeet } = useTilanimikeet()

watch(searchForm, (value) => {
    toimintatiedot.value = value.huonekortti
})

</script>