<template>
    <ComCardExpand title="Uutta Vahdissa" :show="false">
        <div v-for="date in dates" :key="date">
            <h2>{{ date }}</h2>
            <div v-for="note in notes?.filter(note => note.date === date)" :key="note.id" class="card-margin-bottom">
                <h3>
                    {{ note.title }}
                </h3> 
                <el-space>
                        <el-tag :type="type(note.type).color" effect="dark">{{ type(note.type).name }}</el-tag>
                        <template v-if="note.tags.length > 0">
                            <el-tag v-for="tag in note.tags" :key="tag" type="info" effect="light">{{ tag }}</el-tag>
                        </template>
                    </el-space>
                <p style="white-space: pre-line" >{{ note.description }}</p>
                <p>
                    <el-link type="primary" :href="note.link" target="_blank">Lue lisää</el-link>
                </p>
            </div>
        </div>
    </ComCardExpand>
</template>

<script setup lang="ts">
import ComCardExpand from '@/components/common/ComCardExpand.vue';
import { computed, watch } from 'vue';
import { NoteType } from '@/types/Note';
import { useNotes } from '@/methods/notesComposables';
import { ElMessage } from 'element-plus';

const { notes, error } = useNotes()
const dates = computed(() => {
    if(!notes.value) {
        return
    }

    return  [...new Set(notes.value.map(note => note.date).reverse())]
})
const type = ( value : string) => {
    const noteType = NoteType[value as keyof typeof NoteType];

    switch (noteType) {
        case NoteType.ench:
            return {
                name: 'OMINAISUUS',
                color: 'success'
            };
        case NoteType.fix:
            return {
                name: 'KORJAUS',
                color: 'danger'
            };
        case NoteType.perf:
            return {
                name: 'SUORITUSKYKY',
                color: 'warning'
            };
        case NoteType.info:
            return {
                name: 'TIEDOTE',
                color: 'info'
            };
        default:
            return {
                name: 'TUNTEMATON',
                color: 'info'
            };
    }
}
watch(error, (value) => {
    if (value) {
        ElMessage.error('Julkaisutietojen haku epäonnistui')
    }
})
</script>
