import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { key: 0 }

import { ref, watch } from 'vue';
import { useHakuComponent } from './haku';
import { HuonekorttiSahkoluokka } from '@/api/models/huonekortti-sahkoluokka';
import ToimintaAika from "@/types/ToimintaAikaEnum"
import { useTilanimikeet } from '@/methods/tilanimikeComposables';

export default /*@__PURE__*/_defineComponent({
  __name: 'ComHakuToimintatiedot',
  setup(__props) {

const { searchForm, isSelected, showHeading } = useHakuComponent()
const toimintatiedot = ref(searchForm.value.huonekortti)
const { tilanimikkeet } = useTilanimikeet()

watch(searchForm, (value) => {
    toimintatiedot.value = value.huonekortti
})


return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_checkbox_button = _resolveComponent("el-checkbox-button")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(showHeading)(0))
      ? (_openBlock(), _createElementBlock("h3", _hoisted_1, "Toimintatiedot"))
      : _createCommentVNode("", true),
    _createVNode(_component_el_form, {
      model: toimintatiedot.value,
      onSubmit: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["prevent"])),
      ref: "form",
      inline: false,
      size: "small"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 20 }, {
          default: _withCtx(() => [
            (_unref(isSelected)('tilanimike'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 0,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "Tilanimike " }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          multiple: "",
                          modelValue: toimintatiedot.value.tilanimike,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((toimintatiedot.value.tilanimike) = $event)),
                          placeholder: "Hae tilanimikkeellä"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tilanimikkeet), (tilanimike) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                value: tilanimike.nimi,
                                key: tilanimike.id
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(tilanimike.nimi), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isSelected)('tilanimike-tasmennys'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 1,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "Tilanimikkeen täsmennys",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: toimintatiedot.value['tilanimike-tasmennys'],
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((toimintatiedot.value['tilanimike-tasmennys']) = $event)),
                          placeholder: "Hae tilanimikkeen täsmennyksellä",
                          minlength: "3"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isSelected)('toiminnan-kuvaus'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 2,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "Toiminnan kuvaus",
                      size: "small"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: toimintatiedot.value['toiminnan-kuvaus'],
                          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((toimintatiedot.value['toiminnan-kuvaus']) = $event)),
                          placeholder: "Hae toiminnan kuvauksella",
                          minlength: "3"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isSelected)('toiminnan-vaatimukset'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 3,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "Toiminnan vaatimukset" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: toimintatiedot.value['toiminnan-vaatimukset'],
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((toimintatiedot.value['toiminnan-vaatimukset']) = $event)),
                          placeholder: "Hae toiminnan vaatimuksilla",
                          minlength: "3"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isSelected)('henkilomaara-norm'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 4,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "Henkiömäärä normaalisti" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: toimintatiedot.value['henkilomaara-norm'],
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((toimintatiedot.value['henkilomaara-norm']) = $event)),
                          placeholder: "Hae henkilömäärä normaalisti"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isSelected)('henkilomaara-max'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 5,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "Henkilömäärä max" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input_number, {
                          modelValue: toimintatiedot.value['henkilomaara-max'],
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((toimintatiedot.value['henkilomaara-max']) = $event)),
                          placeholder: "Hae henkilömäärä maksimi"
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isSelected)('sahkoluokka'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 6,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "Sähköluokka" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_checkbox_group, {
                          modelValue: toimintatiedot.value.sahkoluokka,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((toimintatiedot.value.sahkoluokka) = $event))
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(HuonekorttiSahkoluokka), (sahkoluokka) => {
                              return (_openBlock(), _createBlock(_component_el_checkbox_button, {
                                value: sahkoluokka,
                                key: sahkoluokka
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(sahkoluokka), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_unref(isSelected)('toiminta-aika'))
              ? (_openBlock(), _createBlock(_component_el_col, {
                  key: 7,
                  span: 12
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "ToimintaAika" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          "allow-create": "",
                          modelValue: toimintatiedot.value['toiminta-aika'],
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((toimintatiedot.value['toiminta-aika']) = $event)),
                          placeholder: "Hae toiminta-ajalla"
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(ToimintaAika), (aika) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                value: aika,
                                key: aika
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(aika), 1)
                                ]),
                                _: 2
                              }, 1032, ["value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ], 64))
}
}

})