<template>
    <template v-if="huonekortti">
        <ComPageHeader :contentTitle="headerTitle" :contentSubtitle="headerSubtitle">
            <div style="float: right">
                <el-space>
                    <el-badge :value="countLiite" :hidden="!countLiite">
                        <ComAttatchmentButton @click="showLiitteet(huonekortti.id, 1)"></ComAttatchmentButton>
                    </el-badge>
                    <el-badge :value="countKommentti" :hidden="!countKommentti">
                        <ComCommentButton @click="showKommentit(huonekortti.id, 1)"></ComCommentButton>
                    </el-badge>
                    <ComHuonekorttiInfoDropDownButton/>
                </el-space>
            </div>
        </ComPageHeader>
        <ComToimintatiedot :data="huonekortti" class="card-margin-bottom"></ComToimintatiedot>
        <ComSuunnittelutiedot></ComSuunnittelutiedot>
        <el-drawer
            v-model="showDrawer"
            size="50%"
            :title="drawerTitle"
            destroy-on-close
            @close="drawerVisible = undefined"> 
            <ComLiitteet 
                :viite="kommenttiTaiLiiteViite" 
                :tyyppi="kommenttiTaiLiiteTyyppi" 
                v-if="drawerVisible == drawerEnum.liitteet" 
            ></ComLiitteet>
            <ComKommentointi 
                :paakayttaja="paakayttaja ?? false" 
                :viite="kommenttiTaiLiiteViite" 
                :tyyppi="kommenttiTaiLiiteTyyppi" 
                v-if="drawerVisible == drawerEnum.kommentointi" 
            ></ComKommentointi>
            <ComHanketiedot 
                :huonekorttiId="huonekortti.id" 
                :hankeId="hanke?.id" 
                :editable="false" 
                v-if="drawerVisible == drawerEnum.hanketiedot"
            ></ComHanketiedot>
            <ComHistoria 
                :huonekortti="huonekortti" 
                v-if="drawerVisible == drawerEnum.historia">
            </ComHistoria>
        </el-drawer>
    </template>
    <ComCard v-else-if="loading">
        <ComLoading />
    </ComCard>
</template>

<script lang="ts">
export default {
    name: "HuonekorttiView",
}
</script>

<script setup lang="ts">
import ComPageHeader from "../components/layout/ComPageHeader.vue"
import ComToimintatiedot from "@/components/huonekortti/ComToimintatiedot.vue"
import ComSuunnittelutiedot from "@/components/huonekortti/ComSuunnittelutiedot.vue"
import ComHanketiedot from "@/components/huonekortti/hanketiedot/ComHanketiedot.vue"
import ComHistoria from "@/components/huonekortti/historia/ComHistoria.vue"
import ComLoading from "@/components/common/ComLoading.vue"
import ComHuonekorttiInfoDropDownButton from "@/components/huonekortti/ComHuonekorttiInfoDropDownButton.vue"
import ComKommentointi from "@/components/kommentointi/ComKommentointi.vue"
import ComLiitteet from "@/components/liitteet/ComLiitteet.vue"
import ComCard from "@/components/common/ComCard.vue"
import { huonekorttiPage } from "./huonekortti"
import ComAttatchmentButton from "@/components/common/buttons/ComAttatchmentButton.vue"
import ComCommentButton from "@/components/common/buttons/ComCommentButton.vue"
import { ref, watch } from "vue"

const {
    huonekortti,
    headerTitle,
    headerSubtitle,
    drawerVisible,
    hanke,
    loading,
    paakayttaja,
    drawerEnum,
    drawerTitle,
    kommenttiTaiLiiteTyyppi,
    kommenttiTaiLiiteViite,
    countKommentti,
    countLiite,
    showKommentit,
    showLiitteet
} = huonekorttiPage()
const showDrawer = ref(false)

watch(drawerVisible, () => {
    if(!drawerVisible) {
        showDrawer.value = false
    } else {
        showDrawer.value = true
    }
})

</script>
<style>
.hanketiedotDrawer {
    overflow-y: scroll !important;
}
.el-drawer__header {
    margin-bottom: 0px !important;
}
.el-descriptions__label:not(.is-bordered-label) {
    width: 200px;
    display: inline-block;
    font-weight: bold;
}
</style>
