import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { watchEffect } from "vue"
import ComLiiteList from "./ComLiiteList.vue"
import kommenttiTyyppi from "@/types/Kommentti";
import { useLiiteComponent } from "./liite";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComLiitteet',
  props: {
    viite: {},
    tyyppi: {}
  },
  setup(__props: any) {

const props = __props
const { viite: _viite, tyyppi: _tyyppi } = useLiiteComponent()

watchEffect(() => {
    if (props.viite == undefined || props.tyyppi == undefined) {
        return
    }
    
    _viite.value = props.viite
    _tyyppi.value = props.tyyppi
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ComLiiteList))
}
}

})