<template>
    <ComKommentit/>
</template>

<script setup lang="ts">
import kommenttiTyyppi from "@/types/Kommentti"
import { watchEffect } from "vue"
import { useKommentitComponent } from "./kommentit"
import ComKommentit from "./ComKommentit.vue";

const props = defineProps<{
    viite: number | undefined,
    tyyppi: kommenttiTyyppi | undefined,
    paakayttaja: boolean,
}>()

const { tyyppi: tyyppiState, viite: viiteState, isPaakayttaja } = useKommentitComponent()

watchEffect(() => {
    if(props.viite == undefined || props.tyyppi == undefined) {
        return
    }

    isPaakayttaja.value = props.paakayttaja
    tyyppiState.value = props.tyyppi
    viiteState.value = props.viite
})
</script>
