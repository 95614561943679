import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import kommenttiTyyppi from "@/types/Kommentti"
import { watchEffect } from "vue"
import { useKommentitComponent } from "./kommentit"
import ComKommentit from "./ComKommentit.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ComKommentointi',
  props: {
    viite: {},
    tyyppi: {},
    paakayttaja: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const { tyyppi: tyyppiState, viite: viiteState, isPaakayttaja } = useKommentitComponent()

watchEffect(() => {
    if(props.viite == undefined || props.tyyppi == undefined) {
        return
    }

    isPaakayttaja.value = props.paakayttaja
    tyyppiState.value = props.tyyppi
    viiteState.value = props.viite
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(ComKommentit))
}
}

})