import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { style: {"height":"40px"} }
const _hoisted_2 = { style: {"width":"15%"} }
const _hoisted_3 = { style: {"width":"5%"} }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { style: {"width":"35%"} }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { style: {"width":"35%"} }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { style: {"padding-right":"20px"} }
const _hoisted_10 = { style: {"width":"135px"} }

import { HuonekorttiNimikeResponse } from "@/api/models/huonekortti-nimike-response"
import { useNimikeUpdate, useNimikeDelete } from "@/methods/huonekorttiComposablses"
import { useNimike } from "@/methods/nimikkeetComposables"
import { computed, PropType, ref, watch } from "vue"
import { Warning } from "@element-plus/icons-vue"
import ComDeleteButton from "../common/buttons/ComDeleteButton.vue"
import ComEditButton from "../common/buttons/ComEditButton.vue"
import ComTooltip from "../common/ComTooltip.vue"
import ComCancelIconButton from "../common/buttons/ComCancelIconButton.vue"
import ComSaveButton from "../common/buttons/ComSaveButton.vue"
import { HuonekorttiNimikeRequestPut } from "@/api/models/huonekortti-nimike-request-put"
import { ElMessage, ElMessageBox } from "element-plus"
import { huonekorttiPage } from "@/views/huonekortti"
import { useSuunnittelutiedot } from "./suunnittelutiedot"
import { OminaisuusResponse } from "@/api/models/ominaisuus-response"
import ComAttatchmentButton from "../common/buttons/ComAttatchmentButton.vue"
import ComCommentButton from "../common/buttons/ComCommentButton.vue"


export default /*@__PURE__*/_defineComponent({
  __name: 'ComSuunnittelutiedotRow',
  props: {
    nimike: {
        type: Object as PropType<HuonekorttiNimikeResponse>,
        required: true,
    },
},
  setup(__props) {

const props = __props 

const nimikeId = ref<number>() 
const id = ref<number>()
const { kirjoitus, showLiitteet, showKommentit } = huonekorttiPage()
const { cancelEdit, setEdit, liitteetCount, kommentitCount } = useSuunnittelutiedot()
const { error: updateError, loading: updateLoading, response: updateResonse, updateNimike } = useNimikeUpdate()
const { error: deleteError, loading: deleteLoading, response: deleteResponse, deleteNimike } = useNimikeDelete()
const { nimike : _nimike } = useNimike(nimikeId)
const ominaisuudet = computed(() => _nimike.value?.ominaisuudet ?? [])
const edited = ref<boolean>(false)
const request = ref<HuonekorttiNimikeRequestPut>({})
const liiteCount = ref<number>()
const kommenttiCount = ref<number>()

const edit = () => {
    edited.value = !edited.value

    request.value = {
        maara: props.nimike.maara,
        tasmennys: props.nimike.tasmennys,
        ominaisuudet: props.nimike.ominaisuudet?.map((ominaisuus: OminaisuusResponse) => {
            return { id: ominaisuus.id }
        }),
        
    } 

    setEdit(props.nimike.id)
}
const cancel = () => {
    edited.value = false

    cancelEdit(props.nimike.id)
}
const update = async () => {
    if(request.value){ 
        updateNimike(props.nimike.id, request.value) 
    }
}
const del = async () => {
    ElMessageBox.confirm("Haluatko varmasti poistaa nimikkeen: " + props.nimike.nimike?.nimi).then(async () => {
        await deleteNimike(props.nimike.id)
    })
}

watch(updateLoading, (value) => {
    if (!value && updateResonse.value) {
        edited.value = false
        cancelEdit(props.nimike.id)

        ElMessage.success("Nimike päivitetty")
    }
})
watch(updateError, (value) => {
    if (value) {
        ElMessage.error("Nimikkeen päivitys epäonnistui")
    }
})
watch(deleteLoading, (value) => {
    if (!value && deleteResponse.value) {
        edited.value = false

        ElMessage.success("Nimikkeen poistettu")
    }
})
watch(deleteError, (value) => {
    if (value) {
        ElMessage.error("Nimikkeen poisto epäonnistui")
    }
})
watch(liitteetCount, (value) => {
    if(value){
        liiteCount.value = value.find(l => l.id == id.value)?.count
    }
})
watch(kommentitCount, (value) => {
    if(value){
        kommenttiCount.value = value.find(l => l.id == id.value)?.count
    }
})
watch(() => props.nimike, (value) => {
    nimikeId.value = value.nimike?.id
    id.value = value.id
}, { immediate: true, deep: true })


return (_ctx: any,_cache: any) => {
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_badge = _resolveComponent("el-badge")!
  const _component_el_space = _resolveComponent("el-space")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, [
      _createTextVNode(_toDisplayString(_unref(_nimike)?.nimi) + " ", 1),
      (!_unref(_nimike)?.kaytossa === true)
        ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            text: "",
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
            size: "small",
            circle: ""
          }, {
            default: _withCtx(() => [
              _createVNode(ComTooltip, { content: "Nimike on poistettu nimikkeistöstä. Korvaa nimike toisella." }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_icon, { size: 20 }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(Warning))
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_3, [
      (edited.value)
        ? (_openBlock(), _createBlock(_component_el_input_number, {
            key: 0,
            min: 0,
            class: "number-input-mini",
            size: "small",
            modelValue: request.value.maara,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((request.value.maara) = $event))
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            innerHTML: __props.nimike.maara
          }, null, 8, _hoisted_4))
    ]),
    _createElementVNode("td", _hoisted_5, [
      (edited.value)
        ? (_openBlock(), _createBlock(_component_el_input, {
            key: 0,
            modelValue: request.value.tasmennys,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((request.value.tasmennys) = $event)),
            type: "textarea",
            autosize: "",
            style: {"width":"100%"}
          }, null, 8, ["modelValue"]))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            innerHTML: __props.nimike.tasmennys
          }, null, 8, _hoisted_6))
    ]),
    _createElementVNode("td", _hoisted_7, [
      (edited.value)
        ? (_openBlock(), _createBlock(_component_el_select, {
            key: 0,
            multiple: "",
            modelValue: request.value.ominaisuudet,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((request.value.ominaisuudet) = $event)),
            placeholder: "Valitse ominaisuudet",
            size: "small",
            style: {"width":"80%"},
            "value-key": "id"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ominaisuudet.value, (ominaisuus, index) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: index,
                  label: ominaisuus.nimi,
                  value: ominaisuus
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : (__props.nimike.ominaisuudet)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(__props.nimike.ominaisuudet, (ominaisuus, index) => {
              return (_openBlock(), _createBlock(_component_el_tag, {
                type: "info",
                size: "small",
                style: {"margin-right":"2px"},
                key: index
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    innerHTML: ominaisuus.nimi
                  }, null, 8, _hoisted_8)
                ]),
                _: 2
              }, 1024))
            }), 128))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("td", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_el_space, null, {
          default: _withCtx(() => [
            (edited.value)
              ? (_openBlock(), _createBlock(ComSaveButton, {
                  key: 0,
                  onClick: update
                }))
              : _createCommentVNode("", true),
            (edited.value)
              ? (_openBlock(), _createBlock(ComCancelIconButton, {
                  key: 1,
                  onClick: cancel
                }))
              : _createCommentVNode("", true),
            (_unref(kirjoitus) && !edited.value)
              ? (_openBlock(), _createBlock(ComEditButton, {
                  key: 2,
                  onClick: edit
                }))
              : _createCommentVNode("", true),
            (_unref(kirjoitus) && !edited.value)
              ? (_openBlock(), _createBlock(ComDeleteButton, {
                  key: 3,
                  onClick: del
                }))
              : _createCommentVNode("", true),
            (!edited.value)
              ? (_openBlock(), _createBlock(_component_el_badge, {
                  key: 4,
                  value: kommenttiCount.value,
                  hidden: !kommenttiCount.value
                }, {
                  default: _withCtx(() => [
                    _createVNode(ComCommentButton, {
                      onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(showKommentit)(__props.nimike.id, 4)))
                    })
                  ]),
                  _: 1
                }, 8, ["value", "hidden"]))
              : _createCommentVNode("", true),
            (!edited.value)
              ? (_openBlock(), _createBlock(_component_el_badge, {
                  key: 5,
                  value: liiteCount.value,
                  hidden: !liiteCount.value
                }, {
                  default: _withCtx(() => [
                    _createVNode(ComAttatchmentButton, {
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(showLiitteet)(__props.nimike.id, 4)))
                    })
                  ]),
                  _: 1
                }, 8, ["value", "hidden"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})