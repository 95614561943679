<template>
    <el-select
        v-if="nimike"
        v-model="poistaOminaisuudet"
        value-key="id"
        multiple
        size="small"
        collapse-tags
        placeholder="Poista ominaisuudet"
        style="width: 150px;">
        <el-option
            v-for="ominaisuus in nimike.ominaisuudet"
            :key="ominaisuus.id"
            :label="ominaisuus.nimi"
            :value="ominaisuus"/>
        <template #footer>
            <ComCancelButton @click="showPoistaOminaisuudet = false">Peruuta</ComCancelButton>
            <ComSuccessButton @click="removeOminaisuudet(poistaOminaisuudet)">Poista</ComSuccessButton>
        </template>
    </el-select>
</template>

<script lang="ts" setup>
import { useNimike } from '@/methods/nimikkeetComposables';
import { useNimikeMuokkausComponent } from './nimikeMuokkaus';
import { ref } from 'vue';
import { OminaisuusResponse } from '@/api/models/ominaisuus-response';
import ComCancelButton from '@/components/common/buttons/ComCancelButton.vue';
import ComSuccessButton from '@/components/common/buttons/ComSuccessButton.vue';

const { nimikeId, showPoistaOminaisuudet, removeOminaisuudet } = useNimikeMuokkausComponent()
const { nimike } = useNimike(nimikeId)
const poistaOminaisuudet = ref<OminaisuusResponse[]>([])
</script>