import { reactive } from "vue"
import http from "@/http-common"

const openWindow = (val: Blob, type: number) => {
    let blob = new Blob()

    if (type == 1) {
        blob = new Blob([val as BlobPart], { type: "application/pdf" })
    } else if (type == 2) {
        blob = new Blob([val as BlobPart], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
    } else if (type == 3) {
        blob = new Blob([val as BlobPart], { type: "application/zip" })
    }

    const objectUrl = window.URL.createObjectURL(blob)
    window.open(objectUrl, "_blank")
}

const state = reactive({
    loading: false,
    loadingLiiteet: false,
    LiitteetEmpty: false,
    error: false,    
})


function loadHuonekorttiRaportti(tyyppi: number, huonekortit: number[]) {
    if (huonekortit.length > 0 && (tyyppi == 1 || tyyppi == 2)) {
        state.loading = true
        state.error = false

        return http.get("/raportointi/huonekortit", {
                responseType: "blob",
                params: {
                    tyyppi: tyyppi,
                    huonekortit: huonekortit.join(","),
                },
        }).then((response) => {
            openWindow(response.data, tyyppi)
        }).catch(() => {
            state.error = true
        }).finally(() => {
            state.loading = false
        })
    }
}
function loadHistoriaRaportti(tyyppi: number, huonekortit: number[], alku: string, loppu: string) {
    if (huonekortit.length > 0 && alku && loppu && (tyyppi == 1 || tyyppi == 2)) {
        state.loading = true
        state.error = false

        return http.get("/raportointi/historia", {
            responseType: "blob",
            params: {
                tyyppi: tyyppi,
                huonekortit: huonekortit.join(","),
                alku: alku,
                loppu: loppu,
            },
        }).then((response) => {
            openWindow(response.data, tyyppi)
        }).catch(() => {
            state.error = true
        }).finally(() => {
            state.loading = false
        })
    }
}
function loadTilaRaportti(tyyppi: number, vastuuyksikot?: number[], huonekortit?: number[]) {
    if (vastuuyksikot || huonekortit) {
        state.loading = true
        state.error = false

        return http.get("/raportointi/tilat", {
            responseType: "blob",
            params: {
                tyyppi: tyyppi,
                ...(vastuuyksikot ? { vastuuyksikot: vastuuyksikot.join(",") } : {}),
                ...(huonekortit ? { huonekortit: huonekortit.join(",") } : {}),
            },
        }).then((response) => {
            openWindow(response.data, tyyppi)
        }).catch(() => {
            state.error = true
        }).finally(() => {
            state.loading = false
        })
    }
}
function loadNimikeRaportti(nimikkeet: number[], vastuuyksikot?: number[], huonekortit?: number[]) {
    if (vastuuyksikot || huonekortit) {
        state.loading = true
        state.error = false

        return http.get("/raportointi/nimikkeet", {
            responseType: "blob",
            params: {
                nimikkeet: nimikkeet.join(","),
                ...(vastuuyksikot ? { vastuuyksikot: vastuuyksikot.join(",") } : {}),
                ...(huonekortit ? { huonekortit: huonekortit.join(",") } : {}),
            },
        }).then((response) => {
            openWindow(response.data, 2)
        }).catch(() => {
            state.error = true
        }).finally(() => {
            state.loading = false
        })
    }
}

function loadHuonekorttiLiitteet(id: number[]) {
    if (id.length > 0) {
        state.loadingLiiteet = true
        state.LiitteetEmpty = false
        state.error = false

        return http.get("/raportointi/huonekortit/liitteet", {
            responseType: "blob",
            params: {
                huonekortit: id.join(","),
            },
        }).then((response) => {
            if(response.status == 200) {
                openWindow(response.data, 3)
            }
            if(response.status == 204) {
                state.LiitteetEmpty = true
            }
        }).catch(() => {
            state.error = true
        }).finally(() => {
            state.loadingLiiteet = false
        })
    }
}

export default {
    loadHuonekorttiRaportti,
    loadHistoriaRaportti,
    loadTilaRaportti,
    loadNimikeRaportti,
    loadHuonekorttiLiitteet,
    state,
}
