import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { style: {"white-space":"pre-line"} }

import ComCardExpand from '@/components/common/ComCardExpand.vue';
import { computed, watch } from 'vue';
import { NoteType } from '@/types/Note';
import { useNotes } from '@/methods/notesComposables';
import { ElMessage } from 'element-plus';


export default /*@__PURE__*/_defineComponent({
  __name: 'ComReleaseNotes',
  setup(__props) {

const { notes, error } = useNotes()
const dates = computed(() => {
    if(!notes.value) {
        return
    }

    return  [...new Set(notes.value.map(note => note.date).reverse())]
})
const type = ( value : string) => {
    const noteType = NoteType[value as keyof typeof NoteType];

    switch (noteType) {
        case NoteType.ench:
            return {
                name: 'OMINAISUUS',
                color: 'success'
            };
        case NoteType.fix:
            return {
                name: 'KORJAUS',
                color: 'danger'
            };
        case NoteType.perf:
            return {
                name: 'SUORITUSKYKY',
                color: 'warning'
            };
        case NoteType.info:
            return {
                name: 'TIEDOTE',
                color: 'info'
            };
        default:
            return {
                name: 'TUNTEMATON',
                color: 'info'
            };
    }
}
watch(error, (value) => {
    if (value) {
        ElMessage.error('Julkaisutietojen haku epäonnistui')
    }
})

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_space = _resolveComponent("el-space")!
  const _component_el_link = _resolveComponent("el-link")!

  return (_openBlock(), _createBlock(ComCardExpand, {
    title: "Uutta Vahdissa",
    show: false
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dates.value, (date) => {
        return (_openBlock(), _createElementBlock("div", { key: date }, [
          _createElementVNode("h2", null, _toDisplayString(date), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notes)?.filter(note => note.date === date), (note) => {
            return (_openBlock(), _createElementBlock("div", {
              key: note.id,
              class: "card-margin-bottom"
            }, [
              _createElementVNode("h3", null, _toDisplayString(note.title), 1),
              _createVNode(_component_el_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_tag, {
                    type: type(note.type).color,
                    effect: "dark"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(type(note.type).name), 1)
                    ]),
                    _: 2
                  }, 1032, ["type"]),
                  (note.tags.length > 0)
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(note.tags, (tag) => {
                        return (_openBlock(), _createBlock(_component_el_tag, {
                          key: tag,
                          type: "info",
                          effect: "light"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tag), 1)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    : _createCommentVNode("", true)
                ]),
                _: 2
              }, 1024),
              _createElementVNode("p", _hoisted_1, _toDisplayString(note.description), 1),
              _createElementVNode("p", null, [
                _createVNode(_component_el_link, {
                  type: "primary",
                  href: note.link,
                  target: "_blank"
                }, {
                  default: _withCtx(() => _cache[0] || (_cache[0] = [
                    _createTextVNode("Lue lisää")
                  ])),
                  _: 2
                }, 1032, ["href"])
              ])
            ]))
          }), 128))
        ]))
      }), 128))
    ]),
    _: 1
  }))
}
}

})