import { computed, Ref } from "vue"
import http from "@/http-common"
import { useQuery } from "@tanstack/vue-query"
import { KalustepakettiResponse } from "@/api/models/kalustepaketti-response"

const url = '/nimikkeet/kalustepaketit'

export function useKalustepaketit(hankeId: Ref<number | undefined>) {
    const queryKey = computed(() => [url, { hankeId: hankeId.value }])

    const fetcher = async () : Promise<KalustepakettiResponse[]> => {
        const response = await http.get(url, { params: { hanke: hankeId.value } })

        return response.data
    }

    const {
        data: kalustepaketit,
        error,
        isLoading: loading,
    } = useQuery({ 
        queryKey: queryKey, 
        queryFn: () => fetcher(),
        enabled: () => hankeId.value !== undefined 
    })

    return {
        kalustepaketit,
        error,
        loading,
    }
}