<template>
    <div class="card-margin-bottom">
        <ComHankkeet></ComHankkeet>
    </div>
    <div>
        <ComReleaseNotes></ComReleaseNotes>
    </div>
</template>

<script lang="ts">
export default {
    name: "EtusivuView",
}
</script>

<script setup lang="ts">
import ComHankkeet from "@/components/etusivu/ComHankkeet.vue"
import ComReleaseNotes from "@/components/etusivu/ComReleaseNotes.vue"

</script>
